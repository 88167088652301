import { AXIOS_REQUEST } from "../../config";
import { Commit, Dispatch } from "vuex";
import { IBudgetPlan, IBudgetPlanComment } from "@/utils/interfaces/IBudgetPlan";

interface BudgetPlanningState {
  futureBudget: IBudgetPlan[];
  futureBudgetFetched: boolean;
  createdFutureBudget: IBudgetPlan | null;
  createdComment: IBudgetPlanComment | null;
  specificFutureBudget: IBudgetPlan | null;
  specificFutureBudgetFetched: boolean;
}

interface BudgetPlanningContext {
  commit: Commit;
  dispatch: Dispatch;
}

const state: BudgetPlanningState = {
  futureBudget: [],
  futureBudgetFetched: false,
  createdFutureBudget: null,
  createdComment: null,
  specificFutureBudget: null,
  specificFutureBudgetFetched: false,
};

const mutations = {
  setFutureBudget(state: BudgetPlanningState, payload: IBudgetPlan[]) {
    state.futureBudget = payload;
  },
  setFutureBudgetFetched(state: BudgetPlanningState, payload: boolean) {
    state.futureBudgetFetched = payload;
  },
  setCreatedFutureBudget(state: BudgetPlanningState, payload: IBudgetPlan | null) {
    state.createdFutureBudget = payload;
  },
  setCreatedComment(state: BudgetPlanningState, payload: IBudgetPlanComment) {
    state.createdComment = payload;
  },
  setSpecificFutureBudget(state: BudgetPlanningState, payload: IBudgetPlan | null) {
    state.specificFutureBudget = payload;
  },
  setSpecificFutureBudgetFetched(state: BudgetPlanningState, payload: boolean) {
    state.specificFutureBudgetFetched = payload;
  },
};

const getters = {
  getFutureBudget: (state: BudgetPlanningState) => state.futureBudget,
  getFutureBudgetFetched: (state: BudgetPlanningState) => state.futureBudgetFetched,
  getCreatedFutureBudget: (state: BudgetPlanningState) => state.createdFutureBudget,
  getCreatedComment: (state: BudgetPlanningState) => state.createdComment,
  getSpecificFutureBudget: (state: BudgetPlanningState) => state.specificFutureBudget,
  getSpecificFutureBudgetFetched: (state: BudgetPlanningState) => state.specificFutureBudgetFetched,
};

const actions = {
  async fetchFutureBudget(context: BudgetPlanningContext) {
    const futureBudget: IBudgetPlan[] = [];
    try {
      const response = await AXIOS_REQUEST("GET", `/futurebudgets`);
      for (const id in response.data) {
        futureBudget.push({
          id: id,
          ...response.data[id],
        });
      }
      context.commit("setFutureBudget", futureBudget);
      context.commit("setFutureBudgetFetched", true);
    } catch (error) {
      console.error(error);
      context.dispatch("updateRequestMessage", `Error: Unable to fetch future budgets`);
    }
  },

  async fetchSpecificFutureBudget(context: BudgetPlanningContext, futureBudgetId: string) {
    let futureBudget: IBudgetPlan = {};
    try {
      const response = await AXIOS_REQUEST("GET", `/futurebudgets/${futureBudgetId}`);
      futureBudget = response.data;
      context.commit("setSpecificFutureBudget", futureBudget);
      context.commit("setSpecificFutureBudgetFetched", true);
    } catch (error) {
      console.error(error);
      context.dispatch("updateRequestMessage", `Error: Unable to fetch future budget`);
    }
  },

  async createFutureBudgetPlan(context: BudgetPlanningContext, payload: IBudgetPlan) {
    const senderId = payload.sender_id;
    const message = payload.description;
    delete payload.sender_id;
    delete payload.description;
    await AXIOS_REQUEST("POST", "/futurebudgets", payload)
      .then((response) => {
        context.dispatch("updateRequestMessage", "Successfully created a budget request.");
        const payload = {
          sender_id: Number(senderId),
          future_budget_id: Number(response.data.id),
          message: message,
        };
        context.commit("setCreatedFutureBudget", response.data);
        context.dispatch("createBudgetPlanComment", payload);
        context.dispatch("fetchFutureBudget");
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", "Error: Unable to create future budget plan!");
        console.error(error);
      });
  },

  async approveFutureBudgetPlan(
    context: BudgetPlanningContext,
    payload: {
      id: number;
      approved_budget: number;
      status: string;
    }
  ) {
    await AXIOS_REQUEST("PUT", `/futurebudgets/${payload.id}`, payload)
      .then((response) => {
        context.dispatch("updateRequestMessage", "Successfully approved future budget request.");
        context.dispatch("fetchFutureBudget");
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", "Error: Unable to approve future budget plan!");
        console.error(error);
      });
  },

  async declineFutureBudgetPlan(
    context: BudgetPlanningContext,
    payload: {
      id: number;
      status: string;
      sender_id?: number;
      description?: string;
    }
  ) {
    const senderId = payload.sender_id;
    const message = payload.description;
    delete payload.sender_id;
    delete payload.description;
    await AXIOS_REQUEST("PUT", `/futurebudgets/${payload.id}`, payload)
      .then((response) => {
        context.dispatch("updateRequestMessage", "Successfully declined future budget request.");
        context.dispatch("fetchFutureBudget");

        const payload = {
          sender_id: Number(senderId),
          future_budget_id: Number(response.data.id),
          message: message,
        };
        context.dispatch("createBudgetPlanComment", payload);
      })
      .catch((error) => {
        context.dispatch("updateRequestMessage", "Error: Unable to decline future budget plan!");
        console.error(error);
      });
  },

  async createBudgetPlanComment(context: BudgetPlanningContext, payload: IBudgetPlanComment) {
    await AXIOS_REQUEST("POST", "/futurebudgetcomments", payload)
      .then((response) => {
        context.commit("setCreatedComment", response.data);
        context.dispatch("fetchSpecificFutureBudget", payload.future_budget_id);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

const productsModule = {
  state,
  getters,
  mutations,
  actions,
};

export default productsModule;

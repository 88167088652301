import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { createApp } from "vue";
import DataEntry from "../views/V_DataEntry.vue";
import FilterProducts from "../views/V_FilterProducts.vue";
import Requests from "../views/V_Requests.vue";
import Archive from "../views/V_Archive.vue";
import ImportInvoiceCsv from "../views/V_ImportInvoiceCsv.vue";
import CreateProdFamily from "../views/V_CreateProdFamily.vue";
import CreateProduct from "../views/V_CreateProduct.vue";
import CreateProject from "../views/V_CreateProject.vue";
import CreateApplication from "../views/V_CreateApplication.vue";
import ShowInvoices from "../views/V_ShowInvoices.vue";
import CostUnitOverview from "../views/V_CostUnitOverview.vue";
import ListPO from "../views/V_ListPO.vue";
import ListPOExpiration from "../views/V_ListPOExpiration.vue";
import Notifications from "../views/V_Notifications.vue";
import ManageCostUnits from "../views/V_ManageCostUnits.vue";
import CreateCostUnit from "../views/V_CreateCostUnit.vue";
import CreateInvoice from "../views/V_CreateInvoice.vue";
import CreateRequest from "../views/V_CreateRequest.vue";
import CreatePlatform from "../views/V_CreatePlatform.vue";
import PlatformOverview from "../views/V_PlatformOverview.vue";
import ManagePlatform from "../views/V_ManagePlatform.vue";
import ProductCUAllocation from "../views/V_ProductCUAllocation.vue";
import PlatformConsumers from "../views/V_PlatformConsumers.vue";
import BudgetOverview from "../views/V_BudgetOverview.vue";
import BudgetPlanning from "../views/V_BudgetPlanning.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/data-entry",
    name: "DataEntry",
    component: DataEntry,
  },
  {
    path: "/",
    name: "FilterProducts",
    component: FilterProducts,
  },
  {
    path: "/all-orders",
    name: "ListPO",
    component: ListPO,
  },
  {
    path: "/orders-expiration",
    name: "ListPOExpiration",
    component: ListPOExpiration,
  },
  {
    path: "/requests",
    name: "Requests",
    component: Requests,
  },
  {
    path: "/archive",
    name: "Archive",
    component: Archive,
  },
  {
    path: "/import-csv",
    name: "ImportInvoiceCsv",
    component: ImportInvoiceCsv,
  },
  {
    path: "/create-prodfamily",
    name: "CreateProdFamily",
    component: CreateProdFamily,
  },
  {
    path: "/create-product",
    name: "CreateProduct",
    component: CreateProduct,
  },
  {
    path: "/create-project",
    name: "CreateProject",
    component: CreateProject,
  },
  {
    path: "/create-app",
    name: "CreateApplication",
    component: CreateApplication,
  },
  {
    path: "/create-invoice",
    name: "CreateInvoice",
    component: CreateInvoice,
  },
  {
    path: "/invoices",
    name: "ShowInvoices",
    component: ShowInvoices,
  },
  {
    path: "/cost-units",
    name: "CostUnitOverview",
    component: CostUnitOverview,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/manage-cost-unit",
    name: "ManageCostUnits",
    component: ManageCostUnits,
  },
  {
    path: "/create-cost-unit",
    name: "CreateCostUnit",
    component: CreateCostUnit,
  },
  {
    path: "/create-request",
    name: "CreateRequest",
    component: CreateRequest,
  },
  {
    path: "/renew-purchase-request",
    name: "RenewRequest",
    component: CreateRequest,
  },
  {
    path: "/create-platform",
    name: "CreatePlatform",
    component: CreatePlatform,
  },
  {
    path: "/platforms",
    name: "PlatformOverview",
    component: PlatformOverview,
  },
  {
    path: "/manage-platform",
    name: "ManagePlatform",
    component: ManagePlatform,
  },
  {
    path: "/product-cost-unit-allocation",
    name: "ProductCUAllocation",
    component: ProductCUAllocation,
  },
  {
    path: "/platform-consumers",
    name: "PlatformCOnsumers",
    component: PlatformConsumers,
  },
  {
    path: "/edit-purchase-request",
    name: "EditRequest",
    component: CreateRequest,
  },
  {
    path: "/budget-overview",
    name: "BudgetOverview",
    component: BudgetOverview,
  },
  {
    path: "/budget-proposal",
    name: "BudgetProposal",
    component: BudgetPlanning,
  },
  {
    path: "/budget-demand",
    name: "BudgetDemand",
    component: BudgetPlanning,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const app = createApp({});
app.use(router);

export default router;

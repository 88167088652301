<template>
  <div class="c-exportdocumentmodal__wrapper">
    <RequestMessage></RequestMessage>
    <div class="c-exportdocumentmodal__container">
      <div class="c-exportdocumentmodal__headline">
        <p class="c-exportdocumentmodal__title" data-testid="projectapp-modal-title">Invoice Export</p>
        <img src="../../../public/remove.svg" height="30" width="30" alt="Close modal icon" @click="closeModal" />
      </div>
      <div class="c-exportdocumentmodal__content">
        <div class="c-exportdocumentmodal__data">
          <p>Generate PDF</p>
          <button class="c-requestbtns cst-button-primary" @click="generatePDF" :disabled="isPdfGenerated">Generate</button>
        </div>
        <div class="c-exportdocumentmodal__data">
          <p>Download Invoice</p>
          <button class="c-requestbtns cst-button-primary" :disabled="!isPdfGenerated" @click="downloadPDF">
            Export as PDF
            <svg fill="#FFFFFF" width="18px" height="18px" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet">
              <path d="M6,13.61h7.61V6H24v8.38h2V6a2,2,0,0,0-2-2H10.87L4,10.87V30a2,2,0,0,0,2,2H24a2,2,0,0,0,2-2H6Zm0-1.92L11.69,6H12v6H6Z" class="clr-i-outline clr-i-outline-path-1"></path>
              <path d="M28.32,16.35a1,1,0,0,0-1.41,1.41L30.16,21H18a1,1,0,0,0,0,2H30.19l-3.28,3.28a1,1,0,1,0,1.41,1.41L34,22Z" class="clr-i-outline clr-i-outline-path-2"></path>
              <rect x="0" y="0" width="36" height="36" fill-opacity="0"></rect>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { computed, defineProps } from "vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  const { SHOW_EXPORT_DOCUMENT_MODAL, DOWNLOAD_PDF } = VUEX_ACTIONS;
  const store = useStore();
  const props = defineProps({
    invoiceID: {
      type: String,
      required: true,
    },
  });
  const isPdfGenerated = computed(() => store.getters.getIsPdfGenerated);
  const generatePDF = () => store.dispatch("generatePDF", props.invoiceID);
  const downloadPDF = () => {
    store.dispatch(DOWNLOAD_PDF);
  };
  const closeModal = () => {
    store.dispatch(SHOW_EXPORT_DOCUMENT_MODAL, false);
    store.commit("setIsPdfGenerated", false);
    store.commit("setPDF", null);
    store.commit("setPDFId", null);
  };
</script>

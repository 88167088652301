import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "cst-new-product-type--wrapper",
  "data-testid": "budgetplanning-wrapper"
}
const _hoisted_2 = {
  key: 0,
  class: "cst-inputbox"
}
const _hoisted_3 = { class: "cst-inputbox--header" }
const _hoisted_4 = { class: "cst-inputbox--header__title" }
const _hoisted_5 = { class: "cst-inputbox--body" }
const _hoisted_6 = { class: "cst-inputbox__form" }
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["value"]
const _hoisted_11 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_12 = {
  key: 1,
  class: "cst-input-field"
}
const _hoisted_13 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_14 = { class: "cst-input-field" }
const _hoisted_15 = { for: "proposed-demanded-budget" }
const _hoisted_16 = ["placeholder"]
const _hoisted_17 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_18 = { class: "cst-input-field" }
const _hoisted_19 = {
  key: 0,
  class: "label-row-wrapper"
}

import { computed, Ref, ref } from "vue";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IProduct, IProductFamily, IProjectApplication } from "@/utils/interfaces/IProductTypes";
  import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { IUser } from "@/utils/interfaces/IUser";
  import C_BudgetRequestReview from "@/components/C_BudgetRequestReview.vue";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_BudgetPlanning',
  setup(__props) {

  const { FETCH_OWNED_PRODUCTS, FETCH_OWNED_PROJECTS, FETCH_OWNED_APPLICATIONS, FETCH_OWNED_PRODUCT_FAMILIES_BUDGET_PLANNING, CREATE_FUTURE_BUDGET_PLAN, FETCH_USERS, FETCH_ALL_PRODUCTS, FETCH_PROJECTS, FETCH_APP } = VUEX_ACTIONS;

  const router = useRouter();
  const store = useStore();
  const currentUser = computed(() => store.getters.getCurrentUser);
  const allUsers = computed(() => store.getters.getUsers);
  const currentUserId = computed(() => {
    const userId = allUsers.value.find((user: IUser) => user.username === currentUser.value);
    return userId.id;
  });
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);

  const entityType = ref("");
  const selectedEntity = ref("");
  const description = ref("");
  const currentYearForecast = ref("");
  const lastYearActuals = ref("");
  const budget = ref<number>();
  const demandTitle = ref("");
  const demandId = ref("");
  const entity: Ref<IProduct[] | IProductFamily[] | IProjectApplication[]> = computed(() => {
    switch (entityType.value) {
      case "productfamily":
        return store.getters.getOwnedFamiliesBudgetPlan || [];
      case "product":
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          return store.getters.getAllProducts || [];
        } else {
          return store.getters.getOwnedProducts || [];
        }
      case "project":
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          return store.getters.getProjects || [];
        } else {
          return store.getters.getOwnedProjects || [];
        }

      case "application":
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          return store.getters.getApplications || [];
        } else {
          return store.getters.getOwnedApplications || [];
        }

      default:
        return "";
    }
  });

  store.dispatch(FETCH_USERS);

  const handleChangeEntityType = () => {
    switch (entityType.value) {
      case "productfamily":
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";
        store.dispatch(FETCH_OWNED_PRODUCT_FAMILIES_BUDGET_PLANNING, {
          username: currentUser.value,
        });
        break;
      case "product":
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";

        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          store.dispatch(FETCH_ALL_PRODUCTS);
        } else {
          store.dispatch(FETCH_OWNED_PRODUCTS, { username: currentUser.value });
        }
        break;
      case "project":
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          store.dispatch(FETCH_PROJECTS);
        } else {
          store.dispatch(FETCH_OWNED_PROJECTS, { username: currentUser.value });
        }
        break;
      case "application":
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          store.dispatch(FETCH_APP);
        } else {
          store.dispatch(FETCH_OWNED_APPLICATIONS, {
            username: currentUser.value,
          });
        }

        break;
      default:
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";
        console.error("Invalid entity type:", entityType.value);
    }
  };

  /* This function checks if the entity is project/application or product/productfamily because
  the current response from the backend for project/application and has totalforecast instead of forecast */
  const isProjectApplication = (entity: IProduct | IProductFamily | IProjectApplication): entity is IProjectApplication => {
    return (entity as IProjectApplication).totalforecast !== undefined;
  };

  const handleChangeEntity = () => {
    for (const ent of entity.value) {
      if (Number(ent.id) == Number(selectedEntity.value)) {
        if (ent.lastyeartotalactuals) {
          lastYearActuals.value = formatNumber((Number(ent.lastyeartotalactuals) * currencyRate.value).toFixed(2)) + " " + currency.value;
        }

        if (ent.forecast) {
          currentYearForecast.value = formatNumber((Number(ent.forecast) * currencyRate.value).toFixed(2)) + " " + currency.value;
        } else if (isProjectApplication(ent)) {
          currentYearForecast.value = formatNumber((Number(ent.totalforecast) * currencyRate.value).toFixed(2)) + " " + currency.value;
        }
        return;
      }
    }
  };

  const handleBudgetRequest = () => {
    const payload = {
      description: description.value,
      entity_type: entityType.value.toLowerCase(),
      status: "proposed",
      sender_id: currentUserId.value,
    };

    if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
      (payload as IBudgetPlan).proposed_budget = Number(budget.value);
      (payload as IBudgetPlan).proposed_by = currentUser.value;
    } else {
      (payload as IBudgetPlan).demand_title = demandTitle.value;
      (payload as IBudgetPlan).demand_id = demandId.value;
      (payload as IBudgetPlan).demanded_budget = budget.value;
    }

    switch (entityType.value.toLowerCase()) {
      case "productfamily":
        (payload as IBudgetPlan).entity_productfamily_id = Number(selectedEntity.value);
        break;
      case "product":
        (payload as IBudgetPlan).entity_product_id = Number(selectedEntity.value);
        break;
      case "project":
        (payload as IBudgetPlan).entity_project_id = Number(selectedEntity.value);
        break;
      case "application":
        (payload as IBudgetPlan).entity_application_id = Number(selectedEntity.value);
        break;
      default:
        console.error("Invalid entity type:", entityType.value);
    }

    store.dispatch(CREATE_FUTURE_BUDGET_PLAN, payload);
    demandTitle.value = "";
    demandId.value = "";
    budget.value = 0;
    description.value = "";
    currentYearForecast.value = "";
    lastYearActuals.value = "";
    selectedEntity.value = "";
    entityType.value = "";
  };

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      true
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(router).currentRoute.value.path.toLowerCase() == "/budget-proposal" ? "New Budget Proposal" : "New Budget Demand"), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                onSubmit: _withModifiers(handleBudgetRequest, ["prevent"])
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[9] || (_cache[9] = _createElementVNode("label", { for: "entity-type" }, [
                      _createTextVNode("Entity Type:"),
                      _createElementVNode("span", { style: {"color":"red"} }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      id: "entity-type",
                      required: "",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((entityType).value = $event)),
                      placeholder: "Select entity type",
                      onChange: handleChangeEntityType,
                      "data-testid": "budget-planning-select-entity-type"
                    }, _cache[8] || (_cache[8] = [
                      _createStaticVNode("<option disabled value=\"\">Select entity type</option><option value=\"productfamily\">Product Family</option><option value=\"product\">Product</option><option value=\"project\">Project</option><option value=\"application\">Application</option>", 5)
                    ]), 544), [
                      [_vModelSelect, entityType.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", { for: "entity" }, [
                      _createTextVNode("Entity:"),
                      _createElementVNode("span", { style: {"color":"red"} }, "*")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      id: "entity",
                      required: "",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedEntity).value = $event)),
                      placeholder: "Select Entity",
                      disabled: !entityType.value,
                      onChange: handleChangeEntity,
                      "data-testid": "budget-planning-select-entity"
                    }, [
                      _cache[10] || (_cache[10] = _createElementVNode("option", {
                        disabled: "",
                        value: ""
                      }, "Select an entity", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(entity.value, (selectedEntity) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: selectedEntity.id,
                          value: selectedEntity.id
                        }, _toDisplayString(selectedEntity.name), 9, _hoisted_10))
                      }), 128))
                    ], 40, _hoisted_9), [
                      [_vModelSelect, selectedEntity.value]
                    ])
                  ]),
                  (_unref(router).currentRoute.value.path.toLowerCase() != '/budget-proposal')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _cache[12] || (_cache[12] = _createElementVNode("label", { for: "demand-title" }, [
                          _createTextVNode(" Demand Title "),
                          _createElementVNode("span", { style: {"color":"red"} }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "cst-input",
                          type: "text",
                          id: "demand-title",
                          required: "",
                          placeholder: "Enter demand title",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((demandTitle).value = $event)),
                          maxlength: "32",
                          "data-testid": "budget-planning-input-demand-title"
                        }, null, 512), [
                          [_vModelText, demandTitle.value]
                        ]),
                        _createElementVNode("span", {
                          class: _normalizeClass(["cst-input-field__indicator", {
                  'cst-input-field__indicator--max': demandTitle.value?.length == 32,
                }])
                        }, _toDisplayString(demandTitle.value?.length ? `${demandTitle.value.length}` : `0`) + "/32", 3)
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(router).currentRoute.value.path.toLowerCase() != '/budget-proposal')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _cache[13] || (_cache[13] = _createElementVNode("label", { for: "demand-id" }, [
                          _createTextVNode(" Demand ID "),
                          _createElementVNode("span", { style: {"color":"red"} }, "*")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "cst-input",
                          type: "text",
                          id: "demand-id",
                          required: "",
                          placeholder: "Enter demand ID",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((demandId).value = $event)),
                          maxlength: "12",
                          "data-testid": "budget-planning-input-demand-id"
                        }, null, 512), [
                          [_vModelText, demandId.value]
                        ]),
                        _createElementVNode("span", {
                          class: _normalizeClass(["cst-input-field__indicator", {
                  'cst-input-field__indicator--max': demandId.value?.length == 12,
                }])
                        }, _toDisplayString(demandId.value?.length ? `${demandId.value.length}` : `0`) + "/12", 3)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", null, [
                    (_unref(router).currentRoute.value.path.toLowerCase() == '/budget-proposal')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _cache[14] || (_cache[14] = _createElementVNode("label", { for: "last-year-actuals" }, " Last Year Actuals ", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "cst-input",
                            type: "text",
                            id: "last-year-actuals",
                            disabled: "",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((lastYearActuals).value = $event)),
                            placeholder: "0,00 EUR",
                            "data-testid": "budget-planning-input-last-year-actuals"
                          }, null, 512), [
                            [_vModelText, lastYearActuals.value]
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("label", _hoisted_15, [
                        _createTextVNode(_toDisplayString(_unref(router).currentRoute.value.path.toLowerCase() == "/budget-proposal" ? "Proposed Budget" : "Demand Estimate"), 1),
                        _cache[15] || (_cache[15] = _createElementVNode("span", { style: {"color":"red"} }, "*", -1))
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "number",
                        id: "budget",
                        min: "1",
                        required: "",
                        placeholder: _unref(router).currentRoute.value.path == '/budget-proposal' ? 'Enter proposed budget' : 'Enter demand estimate',
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((budget).value = $event)),
                        "data-testid": "budget-planning-budget"
                      }, null, 8, _hoisted_16), [
                        [_vModelText, budget.value]
                      ])
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    (_unref(router).currentRoute.value.path.toLowerCase() == '/budget-proposal')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _cache[16] || (_cache[16] = _createElementVNode("label", { for: "current-year-forecast" }, " Current Year Forecast ", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "cst-input",
                            type: "text",
                            id: "current-year-forecast",
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((currentYearForecast).value = $event)),
                            disabled: "",
                            placeholder: "0,00 EUR",
                            "data-testid": "budget-planning-input-current-year-forecast"
                          }, null, 512), [
                            [_vModelText, currentYearForecast.value]
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_18, [
                      _cache[17] || (_cache[17] = _createElementVNode("label", { for: "description" }, "Description:", -1)),
                      _withDirectives(_createElementVNode("textarea", {
                        class: "cst-input cst-input__description",
                        type: "textarea",
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((description).value = $event)),
                        id: "description",
                        maxlength: "240",
                        "data-testid": "budget-planning-input-description"
                      }, null, 512), [
                        [_vModelText, description.value]
                      ]),
                      _createElementVNode("span", {
                        class: _normalizeClass(["cst-input-field__indicator", {
                    'cst-input-field__indicator--max': description.value?.length == 240,
                  }])
                      }, _toDisplayString(description.value?.length ? `${description.value.length}` : `0`) + "/240", 3)
                    ])
                  ])
                ]),
                (_unref(router).currentRoute.value.path.toLowerCase() == '/budget-demand')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, _cache[18] || (_cache[18] = [
                      _createElementVNode("p", { class: "label" }, "Note:", -1),
                      _createElementVNode("p", { class: "label-message" }, "Budget demand can only be created by the entity owner.", -1)
                    ])))
                  : _createCommentVNode("", true),
                _cache[19] || (_cache[19] = _createElementVNode("div", { class: "cst-inputbox__actions" }, [
                  _createElementVNode("div", { class: "cst-inputbox--flex-wrapper" }, [
                    _createElementVNode("button", {
                      class: "submit-button cst-button cst-button-primary",
                      type: "submit",
                      "data-testid": "budget-planning-submit-button"
                    }, "Submit")
                  ])
                ], -1))
              ], 32)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(C_BudgetRequestReview)
  ], 64))
}
}

})
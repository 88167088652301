<template>
  <RequestMessage></RequestMessage>
  <div class="cst-new-product-type--wrapper">
    <div class="cst-inputbox" v-if="hasPermission" data-testid="newprod-input-box">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title">Create a new Product Family</div>
      </div>

      <div class="cst-inputbox--body">
        <form @submit.prevent="submitForm" data-testid="newprod-submit-form">
          <div class="cst-inputbox__form">
            <div class="cst-input-field">
              <label for="owner">Owner:<span style="color: red">*</span></label>

              <select class="cst-select-field" id="owner" @change="handleSelectOwner($event)" v-model="selectedOwner" required :disabled="submitIsDisabled" data-testid="newprod-select-owner">
                <option v-for="owner in users" :key="owner.id" :value="owner.username">
                  {{ owner.username }}
                </option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="name">Name:<span style="color: red">*</span></label>
              <input class="cst-input" type="text" v-model="productFamilyData.name" id="name" required maxlength="32" :disabled="submitIsDisabled" data-testid="newprod-name" autocomplete="name" />
              <span
                class="cst-input-field__indicator"
                :class="{
                  'cst-input-field__indicator--max': productFamilyData.name?.length == 32,
                }"
                >{{ productFamilyData.name?.length ? `${productFamilyData.name.length}` : `0` }}/32</span
              >
            </div>

            <div class="cst-input-field">
              <label for="year">Year:<span style="color: red">*</span></label>
              <input class="cst-input" type="number" v-model="productFamilyData.year" id="year" min="1900" max="3000" required :disabled="submitIsDisabled" data-testid="newprod-pf-year" />
            </div>
            <!-- Add property to configuration topdownenabled to decide if the field should be rendered and if it's required  -->
            <div class="cst-input-field" v-if="true">
              <label for="year">Budget Limit:<span style="color: red">*</span></label>
              <input class="cst-input" type="number" v-model="productFamilyData.budget_limit" id="budgetLimit" :disabled="submitIsDisabled" data-testid="newprod-pf-budgetLimit" :required="true" min="1" />
            </div>
          </div>

          <div class="cst-inputbox__actions">
            <div class="cst-inputbox--flex-wrapper">
              <button class="submit-button cst-button cst-button-primary" type="submit" data-testid="newprod-submit-button" :disabled="submitIsDisabled">Submit</button>

              <button v-if="createdProductFamily" class="submit-button cst-button cst-button-primary" data-testid="newprod-new-pf-button" @click="handleNewProductFamily">New Product Family</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else class="cst-spinner" data-testid="newprod-spinner"></div>

    <NewProductForm v-if="createdProductFamily && canCreateProduct()" :preSelectedProductFamily="true" :hasBudgetLimit="productFamilyData.budget_limit !== null && productFamilyData.budget_limit !== undefined"></NewProductForm>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, Ref, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { IProductFamily } from "@/utils/interfaces/IProductTypes";
  import { IUser } from "@/utils/interfaces/IUser";
  import NewProductForm from "../components/forms/C_NewProductForm.vue";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { useRouter } from "vue-router";

  export default defineComponent({
    name: "NewProductFamily",
    components: {
      NewProductForm,
      RequestMessage,
    },
    setup() {
      const { FETCH_ALL_PRODUCT_FAMILIES, CREATE_NEW_PRODUCT_FAMILY, FETCH_REGIONS, UPDATE_REQUEST_MESSAGE, FETCH_USERS, FETCH_NOTIFICATIONS, FETCH_PRODUCT_FAMILY_REMAINING_BUDGET } = VUEX_ACTIONS;
      const store = useStore();
      const router = useRouter();
      const createdProductFamily = ref(false);
      const createdProductFamilyId = computed(() => store.getters.getCreatedProductFamily);
      const submitIsDisabled = ref(false);
      const hasPermission = ref(false);
      const users = computed(() => {
        return (store.getters.getUsers || []).slice().sort((a: IUser, b: IUser) => a.username.localeCompare(b.username));
      });
      const userRoles = computed(() => store.getters.getCurrentUserRoles);
      const currentUser = computed(() => store.getters.getCurrentUser);
      const selectedOwner = ref(`${currentUser.value}`);
      const allProductFamilies = computed(() => store.getters.getAllProductFamilies);
      const currentYear = new Date().getFullYear();
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

      onMounted(() => {
        store.dispatch(FETCH_REGIONS);
        store.dispatch(FETCH_USERS);
      });

      const fetchPermissions = () => {
        if (userRoles.value.includes("edit-product-family")) {
          hasPermission.value = true;
        } else {
          router.push("/");
        }
      };

      const canCreateProduct = () => {
        return userRoles.value.includes("edit-product-hierarchy");
      };

      fetchPermissions();

      const productFamilyData: Ref<IProductFamily> = ref({
        name: "",
        owner: null,
        year: currentYear,
        budgetLimit: undefined,
      });

      const submitForm = async () => {
        if (!productFamilyData.value.owner) {
          handleCurrentOwner();
        }
        const existingProductFamily = allProductFamilies.value.find((pf: IProductFamily) => pf.name.toLowerCase() === productFamilyData.value.name.toLowerCase());
        if (!existingProductFamily) {
          await store.dispatch(CREATE_NEW_PRODUCT_FAMILY, productFamilyData.value);
          setTimeout(() => store.dispatch(FETCH_ALL_PRODUCT_FAMILIES), 500);
          submitIsDisabled.value = true;
          setTimeout(() => (createdProductFamily.value = true), 100);
          store.dispatch(FETCH_PRODUCT_FAMILY_REMAINING_BUDGET, Number(createdProductFamilyId.value?.id));
        } else {
          store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Product Family with name you provided already exists!");
          productFamilyData.value.name = "";
        }
      };

      const handleSelectOwner = (event: Event) => {
        const user = users.value.filter((p: IUser) => p.username == (event?.target as HTMLSelectElement).value);
        productFamilyData.value.owner = Number(user[0].id);
      };

      const handleCurrentOwner = () => {
        const user = users.value.filter((p: IUser) => p.username == selectedOwner.value);
        productFamilyData.value.owner = Number(user[0].id);
      };

      const handleNewProductFamily = () => {
        createdProductFamily.value = false;
        productFamilyData.value.name = "";
        submitIsDisabled.value = false;
        productFamilyData.value.budget_limit = undefined;
      };

      watch([userRoles], () => {
        fetchPermissions();
      });

      return {
        productFamilyData,
        submitForm,
        createdProductFamily,
        submitIsDisabled,
        handleNewProductFamily,
        users,
        selectedOwner,
        handleSelectOwner,
        hasPermission,
        canCreateProduct,
      };
    },
  });
</script>

<template>
  <RequestMessage></RequestMessage>
  <div class="cst-new-product-type--wrapper" data-testid="budgetplanning-wrapper">
    <div v-if="true" class="cst-inputbox">
      <div class="cst-inputbox--header">
        <div class="cst-inputbox--header__title">
          {{ router.currentRoute.value.path.toLowerCase() == "/budget-proposal" ? "New Budget Proposal" : "New Budget Demand" }}
        </div>
      </div>

      <div class="cst-inputbox--body">
        <form @submit.prevent="handleBudgetRequest">
          <div class="cst-inputbox__form">
            <div class="cst-input-field">
              <label for="entity-type">Entity Type:<span style="color: red">*</span></label>
              <select class="cst-select-field" id="entity-type" required v-model="entityType" placeholder="Select entity type" @change="handleChangeEntityType" data-testid="budget-planning-select-entity-type">
                <option disabled value="">Select entity type</option>
                <option value="productfamily">Product Family</option>
                <option value="product">Product</option>
                <option value="project">Project</option>
                <option value="application">Application</option>
              </select>
            </div>

            <div class="cst-input-field">
              <label for="entity">Entity:<span style="color: red">*</span></label>
              <select class="cst-select-field" id="entity" required v-model="selectedEntity" placeholder="Select Entity" :disabled="!entityType" @change="handleChangeEntity" data-testid="budget-planning-select-entity">
                <option disabled value="">Select an entity</option>

                <option v-for="selectedEntity in entity" :key="selectedEntity.id" :value="selectedEntity.id">
                  {{ selectedEntity.name }}
                </option>
              </select>
            </div>

            <div class="cst-input-field" v-if="router.currentRoute.value.path.toLowerCase() != '/budget-proposal'">
              <label for="demand-title">
                Demand Title
                <span style="color: red">*</span></label
              >
              <input class="cst-input" type="text" id="demand-title" required placeholder="Enter demand title" v-model="demandTitle" maxlength="32" data-testid="budget-planning-input-demand-title" />
              <span
                class="cst-input-field__indicator"
                :class="{
                  'cst-input-field__indicator--max': demandTitle?.length == 32,
                }"
                >{{ demandTitle?.length ? `${demandTitle.length}` : `0` }}/32</span
              >
            </div>

            <div class="cst-input-field" v-if="router.currentRoute.value.path.toLowerCase() != '/budget-proposal'">
              <label for="demand-id">
                Demand ID
                <span style="color: red">*</span></label
              >
              <input class="cst-input" type="text" id="demand-id" required placeholder="Enter demand ID" v-model="demandId" maxlength="12" data-testid="budget-planning-input-demand-id" />
              <span
                class="cst-input-field__indicator"
                :class="{
                  'cst-input-field__indicator--max': demandId?.length == 12,
                }"
                >{{ demandId?.length ? `${demandId.length}` : `0` }}/12</span
              >
            </div>
            <div>
              <div class="cst-input-field" v-if="router.currentRoute.value.path.toLowerCase() == '/budget-proposal'">
                <label for="last-year-actuals"> Last Year Actuals </label>
                <input class="cst-input" type="text" id="last-year-actuals" disabled v-model="lastYearActuals" placeholder="0,00 EUR" data-testid="budget-planning-input-last-year-actuals" />
              </div>

              <div class="cst-input-field">
                <label for="proposed-demanded-budget"> {{ router.currentRoute.value.path.toLowerCase() == "/budget-proposal" ? "Proposed Budget" : "Demand Estimate" }}<span style="color: red">*</span></label>
                <input class="cst-input" type="number" id="budget" min="1" required :placeholder="router.currentRoute.value.path == '/budget-proposal' ? 'Enter proposed budget' : 'Enter demand estimate'" v-model="budget" data-testid="budget-planning-budget" />
              </div>
            </div>
            <div>
              <div class="cst-input-field" v-if="router.currentRoute.value.path.toLowerCase() == '/budget-proposal'">
                <label for="current-year-forecast"> Current Year Forecast </label>
                <input class="cst-input" type="text" id="current-year-forecast" v-model="currentYearForecast" disabled placeholder="0,00 EUR" data-testid="budget-planning-input-current-year-forecast" />
              </div>

              <div class="cst-input-field">
                <label for="description">Description:</label>
                <textarea class="cst-input cst-input__description" type="textarea" v-model="description" id="description" maxlength="240" data-testid="budget-planning-input-description"></textarea>
                <span
                  class="cst-input-field__indicator"
                  :class="{
                    'cst-input-field__indicator--max': description?.length == 240,
                  }"
                  >{{ description?.length ? `${description.length}` : `0` }}/240</span
                >
              </div>
            </div>
          </div>

          <div v-if="router.currentRoute.value.path.toLowerCase() == '/budget-demand'" class="label-row-wrapper">
            <p class="label">Note:</p>
            <p class="label-message">Budget demand can only be created by the entity owner.</p>
          </div>

          <div class="cst-inputbox__actions">
            <div class="cst-inputbox--flex-wrapper">
              <button class="submit-button cst-button cst-button-primary" type="submit" data-testid="budget-planning-submit-button">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <C_BudgetRequestReview></C_BudgetRequestReview>
</template>
<script lang="ts" setup>
  import { computed, Ref, ref } from "vue";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IProduct, IProductFamily, IProjectApplication } from "@/utils/interfaces/IProductTypes";
  import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { IUser } from "@/utils/interfaces/IUser";
  import C_BudgetRequestReview from "@/components/C_BudgetRequestReview.vue";
  const { FETCH_OWNED_PRODUCTS, FETCH_OWNED_PROJECTS, FETCH_OWNED_APPLICATIONS, FETCH_OWNED_PRODUCT_FAMILIES_BUDGET_PLANNING, CREATE_FUTURE_BUDGET_PLAN, FETCH_USERS, FETCH_ALL_PRODUCTS, FETCH_PROJECTS, FETCH_APP } = VUEX_ACTIONS;

  const router = useRouter();
  const store = useStore();
  const currentUser = computed(() => store.getters.getCurrentUser);
  const allUsers = computed(() => store.getters.getUsers);
  const currentUserId = computed(() => {
    const userId = allUsers.value.find((user: IUser) => user.username === currentUser.value);
    return userId.id;
  });
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);

  const entityType = ref("");
  const selectedEntity = ref("");
  const description = ref("");
  const currentYearForecast = ref("");
  const lastYearActuals = ref("");
  const budget = ref<number>();
  const demandTitle = ref("");
  const demandId = ref("");
  const entity: Ref<IProduct[] | IProductFamily[] | IProjectApplication[]> = computed(() => {
    switch (entityType.value) {
      case "productfamily":
        return store.getters.getOwnedFamiliesBudgetPlan || [];
      case "product":
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          return store.getters.getAllProducts || [];
        } else {
          return store.getters.getOwnedProducts || [];
        }
      case "project":
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          return store.getters.getProjects || [];
        } else {
          return store.getters.getOwnedProjects || [];
        }

      case "application":
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          return store.getters.getApplications || [];
        } else {
          return store.getters.getOwnedApplications || [];
        }

      default:
        return "";
    }
  });

  store.dispatch(FETCH_USERS);

  const handleChangeEntityType = () => {
    switch (entityType.value) {
      case "productfamily":
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";
        store.dispatch(FETCH_OWNED_PRODUCT_FAMILIES_BUDGET_PLANNING, {
          username: currentUser.value,
        });
        break;
      case "product":
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";

        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          store.dispatch(FETCH_ALL_PRODUCTS);
        } else {
          store.dispatch(FETCH_OWNED_PRODUCTS, { username: currentUser.value });
        }
        break;
      case "project":
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          store.dispatch(FETCH_PROJECTS);
        } else {
          store.dispatch(FETCH_OWNED_PROJECTS, { username: currentUser.value });
        }
        break;
      case "application":
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";
        if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
          store.dispatch(FETCH_APP);
        } else {
          store.dispatch(FETCH_OWNED_APPLICATIONS, {
            username: currentUser.value,
          });
        }

        break;
      default:
        selectedEntity.value = "";
        currentYearForecast.value = "";
        lastYearActuals.value = "";
        console.error("Invalid entity type:", entityType.value);
    }
  };

  /* This function checks if the entity is project/application or product/productfamily because
  the current response from the backend for project/application and has totalforecast instead of forecast */
  const isProjectApplication = (entity: IProduct | IProductFamily | IProjectApplication): entity is IProjectApplication => {
    return (entity as IProjectApplication).totalforecast !== undefined;
  };

  const handleChangeEntity = () => {
    for (const ent of entity.value) {
      if (Number(ent.id) == Number(selectedEntity.value)) {
        if (ent.lastyeartotalactuals) {
          lastYearActuals.value = formatNumber((Number(ent.lastyeartotalactuals) * currencyRate.value).toFixed(2)) + " " + currency.value;
        }

        if (ent.forecast) {
          currentYearForecast.value = formatNumber((Number(ent.forecast) * currencyRate.value).toFixed(2)) + " " + currency.value;
        } else if (isProjectApplication(ent)) {
          currentYearForecast.value = formatNumber((Number(ent.totalforecast) * currencyRate.value).toFixed(2)) + " " + currency.value;
        }
        return;
      }
    }
  };

  const handleBudgetRequest = () => {
    const payload = {
      description: description.value,
      entity_type: entityType.value.toLowerCase(),
      status: "proposed",
      sender_id: currentUserId.value,
    };

    if (router.currentRoute.value.path.toLowerCase() == "/budget-proposal") {
      (payload as IBudgetPlan).proposed_budget = Number(budget.value);
      (payload as IBudgetPlan).proposed_by = currentUser.value;
    } else {
      (payload as IBudgetPlan).demand_title = demandTitle.value;
      (payload as IBudgetPlan).demand_id = demandId.value;
      (payload as IBudgetPlan).demanded_budget = budget.value;
    }

    switch (entityType.value.toLowerCase()) {
      case "productfamily":
        (payload as IBudgetPlan).entity_productfamily_id = Number(selectedEntity.value);
        break;
      case "product":
        (payload as IBudgetPlan).entity_product_id = Number(selectedEntity.value);
        break;
      case "project":
        (payload as IBudgetPlan).entity_project_id = Number(selectedEntity.value);
        break;
      case "application":
        (payload as IBudgetPlan).entity_application_id = Number(selectedEntity.value);
        break;
      default:
        console.error("Invalid entity type:", entityType.value);
    }

    store.dispatch(CREATE_FUTURE_BUDGET_PLAN, payload);
    demandTitle.value = "";
    demandId.value = "";
    budget.value = 0;
    description.value = "";
    currentYearForecast.value = "";
    lastYearActuals.value = "";
    selectedEntity.value = "";
    entityType.value = "";
  };
</script>

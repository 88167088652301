import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-importcsv__wrapper" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "v-importcsv__messages"
}
const _hoisted_5 = {
  key: 0,
  class: "v-importcsv__messages-complete v-importcsv__text-center"
}
const _hoisted_6 = {
  key: 1,
  class: "v-importcsv__upload-log"
}
const _hoisted_7 = { class: "v-importcsv__messages-green" }
const _hoisted_8 = { class: "v-importcsv__bold" }
const _hoisted_9 = { class: "v-importcsv__messages-pink" }
const _hoisted_10 = { class: "v-importcsv__bold" }
const _hoisted_11 = { class: "v-importcsv__upload-log-btn-wrapper" }
const _hoisted_12 = {
  key: 0,
  id: "csv-import-table-container",
  class: "v-importcsv__table-container"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  id: "export-table",
  style: {"overflow":"hidden"},
  class: "v-importcsv__table",
  "aria-describedby": "Failed imports table"
}
const _hoisted_15 = { class: "v-importcsv__table-row" }
const _hoisted_16 = {
  key: 0,
  class: "v-importcsv__table-error-warning"
}
const _hoisted_17 = {
  key: 0,
  class: "v-importcsv__table-error-warning"
}
const _hoisted_18 = {
  key: 1,
  class: "v-importcsv__no-failed-inputs v-importcsv__text-center"
}
const _hoisted_19 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("h1", null, "Import CSV file", -1)),
      _createElementVNode("input", {
        class: "v-importcsv__input",
        type: "file",
        ref: "fileInput",
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args)))
      }, null, 544),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          type: "button",
          class: "cst-button cst-button-primary v-importcsv__uploadbtn",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.uploadFile && _ctx.uploadFile(...args))),
          disabled: _ctx.selectedFile === null
        }, "Upload CSV", 8, _hoisted_2)
      ]),
      (_ctx.isUploading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[3] || (_cache[3] = [
            _createElementVNode("div", { class: "cst-spinner" }, null, -1),
            _createElementVNode("div", null, [
              _createElementVNode("p", { class: "v-importcsv__text-center" }, "Upload in progress, please wait...")
            ], -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.uploadComplete)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, "Upload complete!"))
              : _createCommentVNode("", true),
            (_ctx.uploadComplete)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, [
                    _cache[4] || (_cache[4] = _createTextVNode(" Succesful Imports: ")),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.successImports), 1)
                  ]),
                  _createVNode(_component_router_link, { to: "/invoices" }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("button", {
                        type: "button",
                        class: "cst-button v-importcsv__upload-log-btn"
                      }, "Go to Invoices", -1)
                    ])),
                    _: 1
                  }),
                  _createElementVNode("p", _hoisted_9, [
                    _cache[6] || (_cache[6] = _createTextVNode(" Failed Imports: ")),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.failedInputs.length), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    (!_ctx.hideDownloadBtns)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: "cst-button v-importcsv__upload-log-btn",
                          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.downloadPDF && _ctx.downloadPDF(...args)))
                        }, "Download PDF"))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    (_ctx.failedInputs && _ctx.failedInputs.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_ctx.failedInputs.length > 0)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_13, "CSV Errors"))
            : _createCommentVNode("", true),
          _createElementVNode("table", _hoisted_14, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_15, [
                (_ctx.failedInputs.length > 0)
                  ? (_openBlock(), _createElementBlock("th", _hoisted_16, "Error"))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.copiedTableHeaders, (key) => {
                  return (_openBlock(), _createElementBlock("th", { key: key }, _toDisplayString(key), 1))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.failedInputs, (item, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: index,
                  class: "v-importcsv__table-row"
                }, [
                  (_ctx.failedInputs.length > 0)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_17, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.errors, (error) => {
                          return (_openBlock(), _createElementBlock("p", { key: error }, _toDisplayString(error), 1))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.copiedTableHeaders, (key) => {
                    return (_openBlock(), _createElementBlock("td", { key: key }, _toDisplayString((item as any)[key]), 1))
                  }), 128))
                ]))
              }), 128))
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_18, [
          (_ctx.successImports)
            ? (_openBlock(), _createElementBlock("p", _hoisted_19, "No failed inputs to display."))
            : _createCommentVNode("", true)
        ]))
  ], 64))
}
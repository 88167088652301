import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "v-createrequest__wrapper",
  "data-testid": "create-request-wrapper"
}
const _hoisted_2 = { class: "v-createrequest__container" }
const _hoisted_3 = {
  class: "v-createrequest__title",
  "data-testid": "create-request-title"
}
const _hoisted_4 = { class: "v-createrequest__inputs" }
const _hoisted_5 = { class: "v-createrequest__inputs-box" }
const _hoisted_6 = { class: "cst-input-field" }
const _hoisted_7 = ["min", "disabled"]
const _hoisted_8 = { class: "cst-input-field" }
const _hoisted_9 = ["min", "disabled"]
const _hoisted_10 = { class: "cst-input-field" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "cst-input-field" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["value"]
const _hoisted_15 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_16 = ["disabled"]
const _hoisted_17 = ["value"]
const _hoisted_18 = {
  key: 1,
  class: "cst-input-field"
}
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "cst-input-field" }
const _hoisted_21 = ["disabled"]
const _hoisted_22 = { class: "v-createrequest__inputs-box" }
const _hoisted_23 = { class: "cst-input-field" }
const _hoisted_24 = ["disabled"]
const _hoisted_25 = { class: "cst-input-field" }
const _hoisted_26 = ["disabled"]
const _hoisted_27 = ["value", "selected"]
const _hoisted_28 = { class: "v-createrequest__inputs-box" }
const _hoisted_29 = { class: "cst-input-field" }
const _hoisted_30 = ["disabled"]
const _hoisted_31 = ["value"]
const _hoisted_32 = { class: "cst-input-field" }
const _hoisted_33 = ["disabled"]
const _hoisted_34 = ["value"]
const _hoisted_35 = { class: "cst-input-field" }
const _hoisted_36 = ["disabled"]
const _hoisted_37 = ["value"]
const _hoisted_38 = { class: "cst-input-field" }
const _hoisted_39 = ["disabled"]
const _hoisted_40 = ["value", "disabled"]
const _hoisted_41 = ["disabled"]
const _hoisted_42 = { class: "v-createrequest__entitycards-wrapper" }
const _hoisted_43 = {
  class: "v-createrequest__entitycards",
  "data-testid": "createrequest-selected-entities"
}
const _hoisted_44 = {
  key: 0,
  class: "v-createrequest__warning",
  "data-testid": "create-request-warning"
}
const _hoisted_45 = { class: "v-createrequest__bottom-row" }
const _hoisted_46 = { class: "v-createrequest__submit-btns" }
const _hoisted_47 = ["disabled"]
const _hoisted_48 = ["disabled"]
const _hoisted_49 = {
  key: 0,
  class: "v-createrequest__list-items--wrapper"
}
const _hoisted_50 = { class: "v-createrequest__container" }
const _hoisted_51 = { class: "cst-input-field" }
const _hoisted_52 = ["value"]
const _hoisted_53 = { class: "cst-input-field" }
const _hoisted_54 = {
  key: 0,
  "data-testid": "create-request-type-undefined"
}
const _hoisted_55 = { class: "cst-input-field" }
const _hoisted_56 = { class: "cst-input-field v-createrequest__time-material" }
const _hoisted_57 = { key: 0 }
const _hoisted_58 = {
  class: "cst-input-field",
  style: {"margin-bottom":"0px"}
}
const _hoisted_59 = {
  class: "cst-input-field",
  style: {"margin-top":"0px"}
}
const _hoisted_60 = { class: "cst-input-field" }
const _hoisted_61 = { class: "cst-input-field" }
const _hoisted_62 = ["disabled"]
const _hoisted_63 = {
  key: 0,
  class: "v-createrequest__list-items--cards"
}
const _hoisted_64 = {
  key: 1,
  class: "v-createrequest__btns-wrapper"
}
const _hoisted_65 = ["disabled"]

import { VUEX_ACTIONS } from "@/utils/constants";
  import { useStore } from "vuex";
  import { Ref, computed, onUnmounted, ref, watch } from "vue";
  import { IPurchaseRequest } from "@/utils/interfaces/IPurchaseRequest";
  import ListItemCard from "@/components/cards/C_ListItemCard.vue";
  import EntityCard from "@/components/cards/C_EntityCard.vue";
  import { fetchTodaysDate } from "@/utils/helpers/fetchTodaysDate";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IListItem } from "@/utils/interfaces/IListItem";
  import { useRouter } from "vue-router";
  import { formatDateForRequests } from "@/utils/helpers/formatDate";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_CreateRequest',
  setup(__props) {

  const {
    FETCH_APP_IN_PRODUCT,
    FETCH_PRODUCTS_IN_PROD_FAMILY,
    FETCH_PROJECTS_IN_PRODUCT,
    CREATE_PURCHASE_REQUEST,
    CHANGE_REQUEST_ENTITIES,
    CHANGE_PURCHASE_REQUEST_STATUS,
    FETCH_SBU,
    FETCH_SUPPLIERS,
    FETCH_ALL_PRODUCT_FAMILIES,
    FETCH_ALL_PRODUCTS,
    FETCH_PROJECTS,
    FETCH_APP,
    FETCH_PURCHASE_REQUESTS,
    CREATE_LIST_ITEM,
    DELETE_PURCHASE_REQUEST,
  } = VUEX_ACTIONS;
  const store = useStore();
  const router = useRouter();
  const suppliers = computed(() => store.getters.getSuppliers);
  const userFamilies = computed(() => store.getters.getAllProductFamilies);
  const userRoles = computed(() => store.getters.getCurrentUserRoles);
  const hasPermission = computed(() => userRoles.value.includes("edit-purchase-request"));
  const currencies = computed(() => store.getters.getAllCurrencies);
  const orderAmount = ref();
  const appConfiguration = computed(() => store.getters.getAppConfiguration);
  const sbus = computed(() => store.getters.getSBU);
  const products = computed(() => store.getters.getProducts);
  const projects = computed(() => store.getters.getProductProjects);
  const requests = computed(() => store.getters.getPurchaseRequests);
  const lastRequest = computed(() => requests.value[requests.value.length - 1]);
  const lastRequestAmount = ref();
  const lastRequestCurrency = ref();
  const applications = computed(() => store.getters.getProductApplications);
  const allProducts = computed(() => store.getters.getAllProducts);
  const allApplications = computed(() => store.getters.getApplications);
  const allProjects = computed(() => store.getters.getProjects);
  const user = computed(() => store.getters.getCurrentUser);
  const requestEntities = computed(() => store.getters.getRequestEntities);
  const requestToEdit = computed(() => store.getters.getRequestToEdit);
  const isPrCreated = computed(() => store.getters.getIsPrCreated);
  const prIDFromArchive = ref("");
  const selectedCurrency = ref("EUR");
  const selectedUserFamily = ref("");
  const selectedProduct = ref("");
  const selectedApplication = ref("");
  const selectedProject = ref("");
  const currentDate = computed(() => fetchTodaysDate());
  const selectedEntity = ref("");
  const selectedStartDate = ref("");
  const selectedEndDate = ref("");
  const orderText = ref("");
  const comment = ref("");
  const accountingElement = ref("");
  const selectedSupplier = ref("");
  const selectedSBU = ref("");
  const entity = ref("");
  const editingArchivePr = ref(false);
  const openAmount = ref();
  const requestData: Ref<any> = ref({});
  const openAmountField = computed(() => {
    if ((openAmount.value && isPrCreated.value) || isEditingPr.value) {
      return formatNumber(openAmount.value) + " " + selectedCurrency.value;
    } else {
      return 0 + " " + selectedCurrency.value;
    }
  });

  const selectedEntities = ref<any[]>([]);
  const listItemData: Ref<IListItem> = ref({
    id: 0,
    name: "",
    type: "",
    costtype: "",
    amount: null,
    position: 0,
    currency: "",
    resourcename: "",
    quantity: null,
  });
  const timeAndMaterialChecked = ref(false);
  const isEditing = ref(false);
  const listItems: Ref<IListItem[]> = ref([]);
  const combined = ref<IListItem[]>([]);

  const isBtnNextDisabled = computed(() => {
    if (orderAmount.value && orderAmount.value < 1) {
      return true;
    } else {
      return false;
    }
  });
  const sbuEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_sbu_enabled;
    } else {
      return false;
    }
  });
  const accountingElementEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.pr_accounting_element;
    } else {
      return false;
    }
  });
  const undefinedEnabled = computed(() => {
    if (Object.keys(appConfiguration.value).length > 0) {
      return appConfiguration.value.undefined_enabled;
    } else {
      return false;
    }
  });
  /* Verify these two are not needed, if they are not needed, remove them completely. */
  /* List items from edit/renew cannot be used due to the way our backend works */

  const listItemsFromEdit = ref([]);

  const combinedListItems = computed(() => {
    if (listItemsFromEdit.value.length > 0) {
      return listItems.value.concat(listItemsFromEdit.value);
    }
    return listItems.value;
  });

  const isEditingPr = computed(() => store.getters.getIsEditingPr);
  const isRenewingPr = computed(() => store.getters.getIsRenewingPr);

  store.dispatch(FETCH_SBU);
  store.dispatch(FETCH_SUPPLIERS);
  store.dispatch(FETCH_ALL_PRODUCT_FAMILIES);
  store.dispatch(FETCH_ALL_PRODUCTS);
  store.dispatch(FETCH_PROJECTS);
  store.dispatch(FETCH_APP);
  store.dispatch(FETCH_PURCHASE_REQUESTS);

  const handleSelectUserFamily = async (event: Event) => {
    selectedUserFamily.value = (event.target as HTMLSelectElement).value;
    await store.dispatch(FETCH_PRODUCTS_IN_PROD_FAMILY, selectedUserFamily.value);
    selectedProduct.value = "";
  };

  const handleSelectProduct = async (event: Event) => {
    selectedProduct.value = (event.target as HTMLSelectElement).value;
    await store.dispatch(FETCH_APP_IN_PRODUCT, selectedProduct.value);
    await store.dispatch(FETCH_PROJECTS_IN_PRODUCT, selectedProduct.value);
    selectedApplication.value = "";
    selectedProject.value = "";
  };

  const handleSelectProject = (event: Event) => {
    selectedProject.value = (event.target as HTMLSelectElement).value;
    selectedApplication.value = "";
  };

  const handleSelectApplication = (event: Event) => {
    selectedApplication.value = (event.target as HTMLSelectElement).value;
    selectedProject.value = "";
  };

  const handleSelectEntity = (event: Event) => {
    selectedEntity.value = (event.target as HTMLSelectElement).value;
    const isProject = findProject((event.target as HTMLSelectElement).value);
    const isApp = findApplication((event.target as HTMLSelectElement).value);
    if (isProject) {
      entity.value = "Project";
      listItemData.value.name = isProject;
    } else if (isApp) {
      entity.value = "Application";
      listItemData.value.name = isApp;
    } else {
      entity.value = "Product";
      listItemData.value.name = findProduct((event.target as HTMLSelectElement).value);
    }
  };

  const handleAddButton = () => {
    selectedEntities.value.push({
      product: selectedProduct.value ? Number(selectedProduct.value) : null,
      project: selectedProject.value ? Number(selectedProject.value) : null,
      application: selectedApplication.value ? Number(selectedApplication.value) : null,
    });
    selectedUserFamily.value = "";
    selectedProduct.value = "";
    selectedApplication.value = "";
    selectedProject.value = "";
  };

  const handleRemoveEntity = (id: number | string) => {
    const indexToRemove = selectedEntities.value.findIndex((entity) => {
      if (entity.application !== null) {
        return entity.application === id;
      } else if (entity.project !== null) {
        return entity.project === id;
      } else {
        return entity.product === Number(id);
      }
    });
    if (indexToRemove !== -1) {
      selectedEntities.value.splice(indexToRemove, 1);
    }
  };

  const handleRemoveListItem = (position: number) => {
    let removedItem = undefined;
    if (listItemsFromEdit.value.length !== 0) {
      removedItem = combinedListItems.value?.find((item) => item.position === position);
      combined.value = combined.value.filter((item) => item.position !== position);
      combined.value.forEach((item, index) => {
        item.position = index + 1;
      });
    } else {
      removedItem = listItems.value.find((item) => item.position === position);
      listItems.value = listItems.value.filter((item) => item.position !== position);
      listItems.value.forEach((item, index) => {
        item.position = index + 1;
      });
    }
    if (removedItem) {
      openAmount.value += parseFloat(removedItem.amount ? removedItem.amount.toString() : "");
    }
  };

  const handleSelectType = (event: Event) => {
    listItemData.value.type = (event.target as HTMLSelectElement).value;
    if (listItemData.value.type === "Undefined") {
      listItemData.value.costtype = "";
    }
  };

  const handleSelectCostType = (event: Event) => {
    listItemData.value.costtype = (event.target as HTMLSelectElement).value;
  };

  const preventDotInInput = () => {
    orderAmount.value = parseInt(orderAmount.value.toString().split(".").join(""));
  };

  const createPurchaseRequest = async () => {
    requestData.value = {
      podate: selectedStartDate.value,
      ordertext: orderText.value,
      supplier: Number(selectedSupplier.value),
      accountingelement: accountingElement.value,
      owner: user.value,
      orderamount: Number(orderAmount.value),
      comments: comment.value,
      currency: selectedCurrency.value,
    };
    lastRequestCurrency.value = requestData.value.currency;
    if (selectedSBU.value !== "") {
      requestData.value.sbu = Number(selectedSBU.value);
    }
    if (selectedEndDate.value !== "") {
      requestData.value.deliverydate = selectedEndDate.value;
    }
  };

  const handleSubmit = async () => {
    if (selectedEntities.value.length > 0 && orderAmount.value > 0 && !isEditing.value) {
      createPurchaseRequest();
      if (router.currentRoute.value.path.toLowerCase() !== "/edit-purchase-request" && router.currentRoute.value.path.toLowerCase() !== "/renew-purchase-request") {
        await store.dispatch(CREATE_PURCHASE_REQUEST, requestData.value);
      } else if (router.currentRoute.value.path.toLowerCase() === "/renew-purchase-request") {
        store.commit("setIsPrCreated", true);
      }
      store.dispatch(CHANGE_REQUEST_ENTITIES, selectedEntities.value);
      setTimeout(() => scrollDown(), 200);
    }
  };

  const findProduct = (productID: string) => {
    const product = allProducts.value.filter((p: { id: string }) => p.id == productID);
    if (product.length !== 0) {
      return product[0].name;
    } else {
      return;
    }
  };

  const findProject = (projectID: string) => {
    const project = allProjects.value.filter((pr: { id: string }) => pr.id == projectID);
    if (project.length !== 0) {
      return project[0].name;
    } else {
      return;
    }
  };

  const findApplication = (appID: string) => {
    const app = allApplications.value.filter((a: { id: string }) => a.id == appID);
    if (app.length !== 0) {
      return app[0].name;
    } else {
      return;
    }
  };

  const handleAddListItem = () => {
    if (listItemData.value.amount !== null && listItemData.value.amount > lastRequestAmount.value) {
      return;
    }
    listItems.value.push({
      ...listItemData.value,
      position: listItems.value.length + 1,
      entity: entity.value,
      entityID: Number(selectedEntity.value),
    });
    openAmount.value -= Number(listItemData.value.amount);

    listItemData.value.amount = null;
    listItemData.value.costtype = "";
    listItemData.value.type = "";
    listItemData.value.name = "";
    listItemData.value.currency = selectedCurrency.value;
    listItemData.value.resourcename = "";
    listItemData.value.quantity = null;
    selectedEntity.value = "";
  };

  const handleSaveBtn = () => {
    const itemsToProcess = listItemsFromEdit.value.length > 0 ? combined.value : listItems.value;

    createPurchaseRequest();

    if (router.currentRoute.value.path.toLowerCase() === "/edit-purchase-request" || router.currentRoute.value.path.toLowerCase() === "/renew-purchase-request") {
      store.dispatch(CREATE_PURCHASE_REQUEST, requestData.value);
    }

    if (router.currentRoute.value.path.toLowerCase() === "/edit-purchase-request") {
      store.dispatch(CHANGE_PURCHASE_REQUEST_STATUS, {
        prID: Number(prIDFromArchive.value),
        status: "Declined",
        isJustification: false,
      });
    }

    /* Temporary solution, should be async and await the purchase request ID */
    setTimeout(() => {
      for (let item of itemsToProcess) {
        let typeId, type;

        if (listItems.value === itemsToProcess) {
          typeId = item.entityID;
          if (item.entity) {
            type = item.entity;
          }
        } else if (item.project !== null) {
          typeId = item.project;
          type = "Project";
        } else if (item.application !== null) {
          typeId = item.application;
          type = "Application";
        } else {
          typeId = item.product;
          type = "Product";
        }

        let itemType;
        if (item.type == "Undefined") {
          itemType = "Undefined";
        } else {
          itemType = item.type + " " + item.costtype;
        }

        const payload = {
          type: type,
          idoftype: Number(typeId),
          purchaserequest: listItemsFromEdit.value.length > 0 ? Number(item.id) : Number(lastRequest.value.id),
          position: Number(item.position),
          name: item.name,
          costtype: itemType,
          resourcename: item.resourcename,
          quantity: Number(item.quantity),
          amount: Number(item.amount),
          currency: selectedCurrency.value,
        };
        store.dispatch(CREATE_LIST_ITEM, {
          requestID: listItemsFromEdit.value.length > 0 ? requestToEdit.value.id : lastRequest.value.id,
          payload: payload,
        });
      }
      setTimeout(() => {
        router.push("/requests");
      }, 500);
    }, 1500);
  };

  onUnmounted(() => {
    store.commit("setIsPrCreated", false);
    store.commit("setIsEditingPr", false);
    store.commit("setIsRenewingPr", false);
  });

  const handleEdit = () => {
    isEditing.value = !isEditing.value;
    listItems.value = [];
    const requestData: IPurchaseRequest = {
      podate: selectedStartDate.value,
      ordertext: orderText.value,
      supplier: Number(selectedSupplier.value),
      accountingelement: accountingElement.value,
      owner: user.value,
      orderamount: Number(orderAmount.value),
      comments: comment.value,
      currency: selectedCurrency.value,
    };

    if (!isEditing.value && router.currentRoute.value.path.toLowerCase() === "/edit-purchase-request") {
      editingArchivePr.value = true;
    } else if (!isEditing.value && router.currentRoute.value.path.toLowerCase() !== "/edit-purchase-request" && router.currentRoute.value.path.toLowerCase() !== "/renew-purchase-request") {
      store.dispatch(DELETE_PURCHASE_REQUEST, lastRequest.value.id);
      store.dispatch(CREATE_PURCHASE_REQUEST, requestData);
    }
  };

  const scrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight || document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  if (isEditingPr.value || isRenewingPr.value) {
    const retrievedDataString = localStorage.getItem("archiveItemEdit");
    if (retrievedDataString !== null) {
      const retrievedData = JSON.parse(retrievedDataString);
      prIDFromArchive.value = retrievedData.id;
      openAmount.value = 0;
      selectedStartDate.value = formatDateForRequests(retrievedData?.podate);
      selectedEndDate.value = formatDateForRequests(retrievedData?.deliverydate);
      orderText.value = retrievedData.ordertext;
      selectedSupplier.value = retrievedData.supplier;
      accountingElement.value = retrievedData?.accountingelement;
      selectedCurrency.value = retrievedData?.currency;
      comment.value = retrievedData?.comments;
      if (retrievedData?.sbu !== null && retrievedData?.sbu !== undefined) {
        selectedSBU.value = retrievedData.sbu;
      }
      selectedEntities.value = retrievedData.listitems;
      orderAmount.value = retrievedData.orderamount;

      for (let item of listItems.value) {
        item.type = item.costtype.split(" ")[0];
        if (item.project) {
          item.entity = "Project";
          item.entityID = item.project;
        } else if (item.application) {
          item.entity = "Application";
          item.entityID = item.application;
        } else {
          item.entity = "Product";
          item.entityID = item.product;
        }
      }
      lastRequestCurrency.value = selectedCurrency.value;
      store.dispatch(CHANGE_REQUEST_ENTITIES, selectedEntities.value);
    }
  }

  if (router.currentRoute.value.path.toLowerCase() === "/edit-purchase-request") {
    store.commit("setIsPrCreated", true);
  }

  watch([isPrCreated, lastRequest, isEditing], () => {
    if (isPrCreated.value) {
      openAmount.value = orderAmount.value;
      let totalAmountFromEntities = 0;
      if (isEditingPr.value && !editingArchivePr.value) {
        for (let entity of selectedEntities.value) {
          totalAmountFromEntities += Number(entity.amount);
        }
        openAmount.value = openAmount.value - totalAmountFromEntities;
      }
    }
  });

  if (!userRoles.value.includes("edit-purchase-request")) {
    router.push("/");
  }

return (_ctx: any,_cache: any) => {
  return (hasPermission.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(router).currentRoute.value.path.toLowerCase() === "/renew-purchase-request" ? "Renew" : isEditingPr.value ? "Edit" : "Create") + " Purchase Request ", 1),
          _createElementVNode("form", {
            class: "v-createrequest__inputs-wrapper",
            onSubmit: _withModifiers(handleSubmit, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[37] || (_cache[37] = _createElementVNode("h3", null, "Request Info", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _cache[36] || (_cache[36] = _createElementVNode("label", { for: "date" }, [
                    _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                    _createTextVNode("Start Date:")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "date",
                    id: "date",
                    required: "",
                    "data-testid": "start-date",
                    min: currentDate.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedStartDate).value = $event)),
                    disabled: isPrCreated.value && !isEditing.value
                  }, null, 8, _hoisted_7), [
                    [_vModelText, selectedStartDate.value]
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _cache[28] || (_cache[28] = _createElementVNode("label", { for: "date" }, "Delivery Date:", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input",
                      type: "date",
                      id: "date",
                      "data-testid": "delivery-date",
                      min: currentDate.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedEndDate).value = $event)),
                      disabled: isPrCreated.value && !isEditing.value
                    }, null, 8, _hoisted_9), [
                      [_vModelText, selectedEndDate.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _cache[29] || (_cache[29] = _createElementVNode("label", { for: "ordertext" }, [
                      _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                      _createTextVNode("Order Text:")
                    ], -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input",
                      type: "text",
                      id: "ordertext",
                      "data-testid": "order-text",
                      required: "",
                      maxlength: "50",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((orderText).value = $event)),
                      disabled: isPrCreated.value && !isEditing.value
                    }, null, 8, _hoisted_11), [
                      [_vModelText, orderText.value]
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["cst-input-field__indicator", {
                    'cst-input-field__indicator--max': orderText.value?.length == 50,
                  }])
                    }, _toDisplayString(orderText.value?.length ? `${orderText.value.length}` : `0`) + "/50", 3)
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[31] || (_cache[31] = _createElementVNode("label", { for: "supplier" }, [
                      _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                      _createTextVNode("Supplier:")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedSupplier).value = $event)),
                      required: "",
                      disabled: isPrCreated.value && !isEditing.value,
                      "data-testid": "supplier"
                    }, [
                      _cache[30] || (_cache[30] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a Supplier", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(suppliers.value, (supplier) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: supplier.id,
                          value: supplier.id
                        }, _toDisplayString(supplier.name), 9, _hoisted_14))
                      }), 128))
                    ], 8, _hoisted_13), [
                      [_vModelSelect, selectedSupplier.value]
                    ])
                  ]),
                  (sbuEnabled.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _cache[33] || (_cache[33] = _createElementVNode("label", { for: "sbu" }, [
                          _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                          _createTextVNode("SBU:")
                        ], -1)),
                        _withDirectives(_createElementVNode("select", {
                          class: "cst-select-field",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedSBU).value = $event)),
                          required: "",
                          disabled: isPrCreated.value && !isEditing.value
                        }, [
                          _cache[32] || (_cache[32] = _createElementVNode("option", {
                            disabled: "",
                            selected: "",
                            value: ""
                          }, "Select SBU", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sbus.value, (sbu) => {
                            return (_openBlock(), _createElementBlock("option", {
                              key: sbu.id,
                              value: sbu.id
                            }, _toDisplayString(sbu.name), 9, _hoisted_17))
                          }), 128))
                        ], 8, _hoisted_16), [
                          [_vModelSelect, selectedSBU.value]
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (accountingElementEnabled.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                        _cache[34] || (_cache[34] = _createElementVNode("label", { for: "accelement" }, [
                          _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                          _createTextVNode("Accounting Element:")
                        ], -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "cst-input",
                          type: "text",
                          id: "accelement",
                          required: "",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((accountingElement).value = $event)),
                          maxlength: "16",
                          disabled: isPrCreated.value && !isEditing.value
                        }, null, 8, _hoisted_19), [
                          [_vModelText, accountingElement.value]
                        ]),
                        _createElementVNode("span", {
                          class: _normalizeClass(["cst-input-field__indicator", {
                    'cst-input-field__indicator--max': accountingElement.value?.length == 16,
                  }])
                        }, _toDisplayString(accountingElement.value?.length ? `${accountingElement.value.length}` : `0`) + "/16", 3)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_20, [
                    _cache[35] || (_cache[35] = _createElementVNode("label", { for: "comment" }, "Comment:", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "cst-input v-createrequest__comment",
                      type: "text",
                      id: "comment",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((comment).value = $event)),
                      maxlength: "90",
                      disabled: isPrCreated.value && !isEditing.value
                    }, null, 8, _hoisted_21), [
                      [_vModelText, comment.value]
                    ]),
                    _createElementVNode("span", {
                      class: _normalizeClass(["cst-input-field__indicator", {
                    'cst-input-field__indicator--max': comment.value?.length == 90,
                  }])
                    }, _toDisplayString(comment.value?.length ? `${comment.value.length}` : `0`) + "/90", 3)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _cache[40] || (_cache[40] = _createElementVNode("h3", null, "Enter Amount", -1)),
                _createElementVNode("div", _hoisted_23, [
                  _cache[38] || (_cache[38] = _createElementVNode("label", { for: "orderamount" }, [
                    _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                    _createTextVNode("Order Amount:")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "cst-input",
                    type: "number",
                    id: "orderamount",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((orderAmount).value = $event)),
                    required: "",
                    min: "0",
                    onInput: preventDotInInput,
                    disabled: isPrCreated.value && !isEditing.value,
                    "data-testid": "order-amount"
                  }, null, 40, _hoisted_24), [
                    [_vModelText, orderAmount.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _cache[39] || (_cache[39] = _createElementVNode("label", { for: "currency" }, [
                    _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                    _createTextVNode("Currency")
                  ], -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "cst-select-field",
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((selectedCurrency).value = $event)),
                    required: "",
                    disabled: isPrCreated.value && !isEditing.value
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currencies.value, (currency) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: currency.id,
                        value: currency.id,
                        selected: currency.id === selectedCurrency.value
                      }, _toDisplayString(currency.id), 9, _hoisted_27))
                    }), 128))
                  ], 8, _hoisted_26), [
                    [_vModelSelect, selectedCurrency.value]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                _cache[49] || (_cache[49] = _createElementVNode("h3", null, "Add Entities", -1)),
                _createElementVNode("div", _hoisted_29, [
                  _cache[42] || (_cache[42] = _createElementVNode("label", { for: "family" }, [
                    _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                    _createTextVNode("Product Family:")
                  ], -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "cst-select-field",
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((selectedUserFamily).value = $event)),
                    onChange: _cache[10] || (_cache[10] = ($event: any) => (handleSelectUserFamily($event))),
                    disabled: userFamilies.value.length === 0 || (isPrCreated.value && !isEditing.value),
                    "data-testid": "create-request-product-family-dropdown"
                  }, [
                    _cache[41] || (_cache[41] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select a Product Family", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(userFamilies.value, (family) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: family.id,
                        value: family.id
                      }, _toDisplayString(family.name), 9, _hoisted_31))
                    }), 128))
                  ], 40, _hoisted_30), [
                    [_vModelSelect, selectedUserFamily.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _cache[44] || (_cache[44] = _createElementVNode("label", { for: "product" }, [
                    _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                    _createTextVNode("Product:")
                  ], -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "cst-select-field",
                    disabled: products.value.length === 0 || selectedUserFamily.value.length === 0 || (isPrCreated.value && !isEditing.value),
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((selectedProduct).value = $event)),
                    onChange: _cache[12] || (_cache[12] = ($event: any) => (handleSelectProduct($event))),
                    "data-testid": "create-request-product-dropdown"
                  }, [
                    _cache[43] || (_cache[43] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select a Product", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(products.value, (product) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: product.id,
                        value: product.id
                      }, _toDisplayString(product.name), 9, _hoisted_34))
                    }), 128))
                  ], 40, _hoisted_33), [
                    [_vModelSelect, selectedProduct.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _cache[46] || (_cache[46] = _createElementVNode("label", { for: "project" }, "Project:", -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "cst-select-field",
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((selectedProject).value = $event)),
                    disabled: projects.value.length === 0 || selectedProduct.value == '' || (isPrCreated.value && !isEditing.value),
                    onChange: _cache[14] || (_cache[14] = ($event: any) => (handleSelectProject($event))),
                    "data-testid": "create-request-project-dropdown"
                  }, [
                    _cache[45] || (_cache[45] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select a Project", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(projects.value, (project) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: project.id,
                        value: project.id
                      }, _toDisplayString(project.name), 9, _hoisted_37))
                    }), 128))
                  ], 40, _hoisted_36), [
                    [_vModelSelect, selectedProject.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _cache[48] || (_cache[48] = _createElementVNode("label", { for: "application" }, "Application:", -1)),
                  _withDirectives(_createElementVNode("select", {
                    class: "cst-select-field",
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((selectedApplication).value = $event)),
                    disabled: applications.value.length === 0 || selectedProduct.value == '' || (isPrCreated.value && !isEditing.value),
                    onChange: _cache[16] || (_cache[16] = ($event: any) => (handleSelectApplication($event))),
                    "data-testid": "create-request-application-dropdown"
                  }, [
                    _cache[47] || (_cache[47] = _createElementVNode("option", {
                      disabled: "",
                      selected: "",
                      value: ""
                    }, "Select an Application", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(applications.value, (application) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: application.id,
                        value: application.id,
                        disabled: isPrCreated.value && !isEditing.value
                      }, _toDisplayString(application.name), 9, _hoisted_40))
                    }), 128))
                  ], 40, _hoisted_39), [
                    [_vModelSelect, selectedApplication.value]
                  ])
                ]),
                _createElementVNode("button", {
                  type: "button",
                  class: "cst-button cst-button-primary v-createrequest__addbtn",
                  disabled: selectedProduct.value == '' || (isPrCreated.value && !isEditing.value),
                  onClick: handleAddButton,
                  "data-testid": "create-request-add-button"
                }, "Add", 8, _hoisted_41)
              ]),
              _createElementVNode("div", _hoisted_42, [
                _cache[50] || (_cache[50] = _createElementVNode("h3", null, "Selected entities:", -1)),
                _createElementVNode("div", _hoisted_43, [
                  (selectedEntities.value.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_44, "Please add at least 1 entity!"))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedEntities.value, (entity, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createVNode(EntityCard, {
                        data: entity,
                        onRemoveEntity: handleRemoveEntity,
                        isprcreated: isPrCreated.value,
                        isediting: isEditing.value
                      }, null, 8, ["data", "isprcreated", "isediting"])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_45, [
              _cache[51] || (_cache[51] = _createElementVNode("p", null, [
                _createTextVNode(" Fields marked with "),
                _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                _createTextVNode("are required. ")
              ], -1)),
              _createElementVNode("div", _hoisted_46, [
                (isPrCreated.value)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "cst-button cst-button-primary v-createrequest__savebtn",
                      type: "button",
                      disabled: isBtnNextDisabled.value || (!orderAmount.value && orderAmount.value <= 0),
                      onClick: handleEdit,
                      "data-testid": "create-request-edit-button"
                    }, _toDisplayString(!isEditing.value ? "Edit" : "Save"), 9, _hoisted_47))
                  : _createCommentVNode("", true),
                _createElementVNode("button", {
                  type: "submit",
                  class: "cst-button cst-button-primary v-createrequest__savebtn",
                  disabled: isBtnNextDisabled.value || (!orderAmount.value && orderAmount.value <= 0) || selectedEntities.value.length === 0 || isPrCreated.value,
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (scrollDown())),
                  "data-testid": "create-request-next-button"
                }, " Next ", 8, _hoisted_48)
              ])
            ])
          ], 32)
        ]),
        (isPrCreated.value && !isEditing.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
              _createElementVNode("div", _hoisted_50, [
                _cache[65] || (_cache[65] = _createElementVNode("div", { class: "v-createrequest__title" }, "Add list items", -1)),
                _createElementVNode("form", {
                  class: "v-createrequest__inputs-wrapper v-createrequest__list-items--menu",
                  onSubmit: _withModifiers(handleAddListItem, ["prevent"])
                }, [
                  _createElementVNode("div", _hoisted_51, [
                    _cache[53] || (_cache[53] = _createElementVNode("label", { for: "entity" }, [
                      _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                      _createTextVNode("Entity:")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      onChange: handleSelectEntity,
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((selectedEntity).value = $event)),
                      "data-testid": "create-request-select-entity",
                      required: ""
                    }, [
                      _cache[52] || (_cache[52] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select Entity", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(requestEntities.value, (entity) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: entity,
                          value: entity.application ? entity.application : entity.project ? entity.project : entity.product
                        }, _toDisplayString(entity.application ? findApplication(entity.application) : entity.project ? findProject(entity.project) : findProduct(entity.product)), 9, _hoisted_52))
                      }), 128))
                    ], 544), [
                      [_vModelSelect, selectedEntity.value]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_53, [
                    _cache[57] || (_cache[57] = _createElementVNode("label", { for: "type" }, [
                      _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                      _createTextVNode("Type:")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      required: "",
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((listItemData.value.type) = $event)),
                      onChange: _cache[20] || (_cache[20] = ($event: any) => (handleSelectType($event))),
                      "data-testid": "create-request-select-type"
                    }, [
                      _cache[54] || (_cache[54] = _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a Type", -1)),
                      (undefinedEnabled.value)
                        ? (_openBlock(), _createElementBlock("option", _hoisted_54, "Undefined"))
                        : _createCommentVNode("", true),
                      _cache[55] || (_cache[55] = _createElementVNode("option", null, "Capital", -1)),
                      _cache[56] || (_cache[56] = _createElementVNode("option", null, "Expense", -1))
                    ], 544), [
                      [_vModelSelect, listItemData.value.type]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_55, [
                    _cache[59] || (_cache[59] = _createElementVNode("label", { for: "costtype" }, [
                      _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                      _createTextVNode("Cost Type:")
                    ], -1)),
                    _withDirectives(_createElementVNode("select", {
                      class: "cst-select-field",
                      required: "",
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((listItemData.value.costtype) = $event)),
                      onChange: _cache[22] || (_cache[22] = ($event: any) => (handleSelectCostType($event))),
                      "data-testid": "create-request-select-cost-type"
                    }, _cache[58] || (_cache[58] = [
                      _createElementVNode("option", {
                        disabled: "",
                        selected: "",
                        value: ""
                      }, "Select a Cost Type", -1),
                      _createElementVNode("option", null, "Infrastructure", -1),
                      _createElementVNode("option", null, "Maintenance", -1),
                      _createElementVNode("option", null, "Enhancement", -1)
                    ]), 544), [
                      [_vModelSelect, listItemData.value.costtype]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_56, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      id: "timeAndMaterial",
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((timeAndMaterialChecked).value = $event))
                    }, null, 512), [
                      [_vModelCheckbox, timeAndMaterialChecked.value]
                    ]),
                    _cache[60] || (_cache[60] = _createElementVNode("label", { for: "timeAndMaterial" }, "Time & Material", -1))
                  ]),
                  _createElementVNode("div", null, [
                    (timeAndMaterialChecked.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                          _createElementVNode("div", _hoisted_58, [
                            _cache[61] || (_cache[61] = _createElementVNode("label", { for: "resourcename" }, [
                              _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                              _createTextVNode("Resource Name:")
                            ], -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "cst-input",
                              type: "text",
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((listItemData.value.resourcename) = $event)),
                              id: "resourcename",
                              maxlength: "32",
                              required: ""
                            }, null, 512), [
                              [_vModelText, listItemData.value.resourcename]
                            ]),
                            _createElementVNode("span", {
                              class: _normalizeClass(["cst-input-field__indicator", {
                    'cst-input-field__indicator--max': listItemData.value?.resourcename?.length == 32,
                  }])
                            }, _toDisplayString(listItemData.value.resourcename?.length ? `${listItemData.value?.resourcename?.length}` : `0`) + "/32", 3)
                          ]),
                          _createElementVNode("div", _hoisted_59, [
                            _cache[62] || (_cache[62] = _createElementVNode("label", { for: "quantity" }, [
                              _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                              _createTextVNode("Person days:")
                            ], -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "cst-input",
                              type: "number",
                              id: "quantity",
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((listItemData.value.quantity) = $event)),
                              required: "",
                              min: "1"
                            }, null, 512), [
                              [_vModelText, listItemData.value.quantity]
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_60, [
                      _cache[63] || (_cache[63] = _createElementVNode("label", { for: "amount" }, [
                        _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                        _createTextVNode("Amount:")
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "number",
                        id: "amount",
                        required: "",
                        min: "0",
                        onInput: preventDotInInput,
                        "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((listItemData.value.amount) = $event)),
                        "data-testid": "create-request-amount"
                      }, null, 544), [
                        [_vModelText, listItemData.value.amount]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _cache[64] || (_cache[64] = _createElementVNode("label", { for: "amount" }, "Open Amount:", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "text",
                        id: "amount",
                        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((openAmountField).value = $event)),
                        disabled: "",
                        "data-testid": "create-request-open-amount"
                      }, null, 512), [
                        [_vModelText, openAmountField.value]
                      ])
                    ]),
                    _createElementVNode("button", {
                      type: "submit",
                      class: "cst-button cst-button-primary",
                      disabled: listItemData.value.amount ? listItemData.value.amount > openAmount.value : false || openAmount.value == 0,
                      "data-testid": "create-request-add-list-item"
                    }, "Add", 8, _hoisted_62)
                  ])
                ], 32)
              ]),
              (listItems.value.length > 0 || listItemsFromEdit.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listItems.value, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.name
                      }, [
                        _createVNode(ListItemCard, {
                          data: item,
                          onRemoveItem: handleRemoveListItem,
                          lastrequestcurrency: lastRequestCurrency.value
                        }, null, 8, ["data", "lastrequestcurrency"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (isPrCreated.value && !isEditing.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_64, [
              _createElementVNode("button", {
                type: "button",
                class: "cst-button cst-button-primary v-createrequest__btn",
                "data-testid": "create-request-save-btn",
                disabled: (listItems.value.length === 0 && listItemsFromEdit.value.length === 0) || openAmount.value !== 0,
                onClick: handleSaveBtn
              }, _toDisplayString(listItemsFromEdit.value.length !== 0 ? "Save" : "Send Request"), 9, _hoisted_65),
              _cache[66] || (_cache[66] = _createElementVNode("button", {
                type: "button",
                class: "cst-button cst-button-danger v-createrequest__btn"
              }, "Cancel Request", -1))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})
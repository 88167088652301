<template>
  <RequestMessage></RequestMessage>
  <div class="v-platformconsumers__wrapper">
    <div class="v-platformconsumers__products-wrapper">
      <div class="v-platformconsumers__summary-overview">
        <div class="v-platformconsumers__summary-overview--title">
          <p v-if="focusedPlatformName !== 'Platform Overview'"><strong>Platform:</strong> {{ focusedPlatformName }}</p>
          <p v-else>{{ focusedPlatformName }}</p>
        </div>
        <div class="c-budgetsummary__wrapper--platform">
          <div>
            <strong>Platform Cost:</strong>
            {{ focusedPlatform.platform_cost ? formatNumber((focusedPlatform.platform_cost * currencyRate).toFixed(2)) : " -" }}
            {{ selectedCurrency }}
          </div>
          <div>
            <strong>Owner:</strong>
            {{ focusedPlatform.owner ? convertUsernameFromId(Number(focusedPlatform.owner)) : " -" }}
          </div>
        </div>
      </div>
      <div>
        <div class="v-platformconsumers__table--wrapper">
          <table class="v-platformconsumers__table--overview" aria-describedby="Table of platforms">
            <thead>
              <tr>
                <th class="v-platformconsumers__table--header">Platform Consumers</th>
              </tr>
            </thead>

            <tbody>
              <tr class="v-platformconsumers__table-row"></tr>
              <tr>
                <td colspan="4">
                  <strong><p class="v-platformconsumers__label">Projects:</p></strong>
                  <div class="v-platformconsumers__li-table-header">
                    <p class="v-platformconsumers__li-table-header--first">No.</p>
                    <p>Name</p>
                    <p>Owner</p>
                    <p>Platform Cost</p>
                  </div>
                  <div v-if="projectsInPlatform.length > 0" class="v-showinvoices__li-table--wrapper">
                    <div v-for="(item, index) in projectsInPlatform" :key="index">
                      <div class="v-showinvoices__li-table-row" v-if="item && typeof item === 'object'">
                        <p class="v-showinvoices__li-table-row--first">
                          {{ index + 1 }}
                        </p>
                        <p>{{ item.name }}</p>
                        <p>{{ convertUsernameFromId(Number(item.owner)) }}</p>
                        <p>
                          {{ formatNumber((item.platform_cost * currencyRate).toFixed(2)) + " " + item.currency }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="v-platformconsumers__no-data">There are no projects associated with this platform.</div>

                  <strong><p class="v-platformconsumers__label">Applications:</p></strong>
                  <div class="v-platformconsumers__li-table-header">
                    <p class="v-platformconsumers__li-table-header--first">No.</p>
                    <p>Name</p>
                    <p>Owner</p>
                    <p>Platform Cost</p>
                  </div>
                  <div v-if="applicationsInPlatform.length > 0" class="v-platformconsumers__li-table--wrapper">
                    <div v-for="(item, index) in applicationsInPlatform" :key="index">
                      <div class="v-showinvoices__li-table-row" v-if="item && typeof item === 'object'">
                        <p class="v-showinvoices__li-table-row--first">
                          {{ index + 1 }}
                        </p>
                        <p>{{ item.name }}</p>
                        <p>{{ convertUsernameFromId(Number(item.owner)) }}</p>
                        <p>
                          {{ formatNumber((item.platform_cost * currencyRate).toFixed(2)) + " " + item.currency }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else class="v-platformconsumers__no-data">There are no applications associated with this platform.</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from "vue";
  import { useRoute } from "vue-router";
  import { useStore } from "vuex";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IPlatform } from "@/utils/interfaces/IPlatform";
  import RequestMessage from "@/components/C_RequestMessage.vue";
  import { IUser } from "@/utils/interfaces/IUser";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { IProduct } from "@/utils/interfaces/IProductTypes";

  const { FETCH_NOTIFICATIONS, FETCH_USERS, FETCH_PROJECTS_IN_PLATFORM, FETCH_APPLICATIONS_IN_PLATFORM, FETCH_ALL_PRODUCTS } = VUEX_ACTIONS;
  const store = useStore();
  const route = useRoute();
  const { platform } = route.query;
  const selectedCurrency = computed(() => store.getters.getSelectedCurrency);

  const loggedUser = computed(() => store.getters.getCurrentUser);
  const users = computed(() => store.getters.getUsers);
  const allProducts = computed(() => store.getters.getAllProducts);
  const currencyRate = computed(() => store.getters.getCurrencyRate);

  const projectsInPlatform = computed(() => store.getters.getProjectsInPlatform);
  const applicationsInPlatform = computed(() => store.getters.getApplicationsInPlatform);

  const focusedPlatform = computed(() => {
    let platformObject: IProduct = {
      productfamily: null,
      name: "",
      region: null,
      owner: null,
      platform_cost: null,
    };
    allProducts.value?.filter((product: IProduct) => {
      if (Number(product.id) === Number(platform)) {
        platformObject = product;
      }
    });
    return platformObject;
  });

  const focusedPlatformName = computed(() => {
    let platformName = "Platform Overview";
    allProducts.value?.filter((product: IPlatform) => {
      if (Number(product.id) === Number(platform)) {
        platformName = product.name;
      }
    });
    return platformName;
  });

  store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
  store.dispatch(FETCH_USERS);
  store.dispatch(FETCH_PROJECTS_IN_PLATFORM, platform);
  store.dispatch(FETCH_APPLICATIONS_IN_PLATFORM, platform);
  store.dispatch(FETCH_ALL_PRODUCTS);

  const convertUsernameFromId = (id: number) => {
    let username = "Unknown";
    users.value.filter((user: IUser) => {
      if (Number(user.id) === id) {
        username = user.username;
      }
    });
    return username;
  };
</script>

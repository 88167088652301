<template>
  <div class="cst-inputbox">
    <div class="cst-inputbox--header">
      <div class="cst-inputbox--header__title">Add a new Product</div>
    </div>

    <div class="cst-inputbox--body" data-testid="newprod-input-box-body">
      <form @submit.prevent="submitForm" data-testid="newprod-form">
        <div class="cst-inputbox__form">
          <div class="cst-input-field">
            <label for="productowner" data-testid="newprod-form-label-owner">Owner:<span style="color: red">*</span></label>
            <select class="cst-select-field" v-model="selectOwner" required id="productowner" :disabled="inputDisabled" placeholder="Select Owner" data-testid="newprod-select-own">
              <option disabled selected value="">Select an owner</option>
              <option v-for="owner in users" :key="owner.id" :value="owner.id" data-testid="newprod-owner">
                {{ owner.username }}
              </option>
            </select>
          </div>
          <div class="cst-input-field">
            <label for="productfamily">Product Family:<span style="color: red">*</span></label>
            <select class="cst-select-field" required @change="handleSelectFamily($event)" :disabled="inputDisabled" v-model="selectedFamily" id="productfamily" name="productfamily" data-testid="newprod-select-family">
              <option disabled selected value="">Select a Product Family</option>
              <option v-for="family in productFamilies" :key="family.id" :value="family.name">
                {{ family.name }}
              </option>
            </select>
          </div>

          <div class="cst-input-field">
            <label for="name">Name:<span style="color: red">*</span></label>
            <input autocomplete="name" class="cst-input" type="text" v-model="productData.name" id="name" required :disabled="inputDisabled" maxlength="32" data-testid="newprod-input-field" placeholder="Enter Product Name" />
            <span
              class="cst-input-field__indicator"
              :class="{
                'cst-input-field__indicator--max': productData.name?.length == 32,
              }"
              data-testid="newprod-input-name"
              >{{ productData.name?.length ? `${productData.name.length}` : `0` }}/32</span
            >
          </div>

          <div class="cst-input-field" v-if="hasBudgetLimit || (availableBudget > 0 && availableBudget != null && availableBudget != undefined)">
            <label for="budgetLimit">Budget Limit:<span style="color: red">*</span></label>
            <input class="cst-input" type="number" v-model="productData.budget_limit" id="budgetLimit" required :disabled="inputDisabled" data-testid="newprod-input-field" :placeholder="placeholderText" :max="availableBudget" />
          </div>

          <div class="cst-input-field">
            <label for="productregion">Region:<span style="color: red">*</span></label>
            <select class="cst-select-field" required :disabled="inputDisabled" v-model="selectedRegion" id="productregion" @change="handleSelectRegions($event)" data-testid="newprod-select-regions">
              <option disabled selected value="">Select a Region</option>

              <option v-for="region in regions" :key="region.id" :value="region.name">
                {{ region.name }}
              </option>
            </select>
          </div>
        </div>

        <span class="cst-input-field__indicator--available" :class="availableBudget && Number(availableBudget) <= 0 ? 'cst-input-field__indicator--min' : ''" data-testid="newprod-input-budget-limit" v-if="availableBudget"
          ><strong>Available amount:</strong> {{ formatNumber((Number(availableBudget) * currencyRate).toFixed(2)) }}</span
        >

        <div class="v-filter-products__platform-row">
          <div v-if="isPlatformSelected" class="cst-input-field">
            <label for="platformCost">Enter platform cost value:<span style="color: red">*</span></label>
            <input type="number" class="cst-input" v-model="platformCost" id="platformCost" placeholder="Enter platform cost value" :required="isPlatformSelected" :disabled="inputDisabled" />
          </div>
          <div class="cst-input-field__checkbox">
            <label for="selectPlatform">Platform?</label>
            <input type="checkbox" id="selectPlatform" v-model="isPlatformSelected" />
          </div>
        </div>

        <div class="cst-inputbox__actions">
          <div class="thumbnail-dropdown">
            <div class="thumbnail-dropdown--btn" v-if="!inputDisabled" @click="toggleDropdown" data-testid="thumbnail-dropdown-btn">
              <img v-if="selectedImage" :src="selectedImage" alt="Product" width="60" height="40" data-testid="newprod-selected-image" />
              <div v-else data-testid="newprod-selected-image-false">Select an Image</div>
            </div>

            <div class="thumbnail-dropdown--content" v-show="dropdownOpen" data-testid="thumbnail-dropdown">
              <img src="../../../public/newimage.png" alt="Add new" width="80" height="50" style="border: 1px solid #cfcfcf" data-testid="newprod-upload-image" @click="uploadNewImage()" />
              <div v-for="image in allImages" :key="image" data-testid="newprod-select-image" @click="handleSelectImage(image)">
                <img :src="image.image_data" :alt="image.name" width="80" height="50" style="object-fit: contain" />
              </div>
            </div>
          </div>

          <div class="cst-inputbox__actions--row-wrapper">
            <button class="submit-button cst-button cst-button-primary" type="submit" :disabled="submitBtnDisabled || (availableBudget && Number(availableBudget) <= 0)" data-testid="newprod-submit-btn">Submit</button>

            <button v-if="productAdded" class="submit-button cst-button cst-button-primary" @click.prevent="handleNew()" data-testid="newprod-new-btn">New</button>
          </div>
        </div>
      </form>
    </div>

    <div v-if="createdProductFlag" class="cst-inputbox__added-content" data-testid="newprod-new-product-section">
      <h4>New Product has been added!</h4>
      <h5>You can add Project or Applications directly to it by clicking on the corresponding buttons.</h5>
      <NewProductCard :product="newProduct" :base64image="selectedImage"></NewProductCard>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onBeforeUnmount, ref, Ref, watch } from "vue";
  import { useStore } from "vuex";
  import { IProduct, IProductFamily } from "@/utils/interfaces/IProductTypes";
  import imageFilenames from "@/utils/assets/imageFilenames.json";
  import NewProductCard from "../cards/C_NewProductCard.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { IImage } from "@/utils/interfaces/IImage";
  import { IUser } from "@/utils/interfaces/IUser";
  import { formatNumber } from "@/utils/helpers/formatNumber";

  export default defineComponent({
    name: "NewProductForm",
    components: {
      NewProductCard,
    },
    props: {
      preSelectedProductFamily: {
        type: Boolean,
        default: false,
      },
      hasBudgetLimit: {
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const { CREATE_NEW_PRODUCT, FETCH_REGIONS, UPDATE_REQUEST_MESSAGE, FETCH_USERS, FETCH_OWNED_PRODUCT_FAMILIES, FETCH_ALL_PRODUCTS, FETCH_ALL_PRODUCT_IMAGES, CLEAR_UPLOADED_IMAGE, UPLOAD_NEW_PRODUCT_IMAGE, FETCH_PRODUCT_FAMILY_REMAINING_BUDGET } =
        VUEX_ACTIONS;
      const store = useStore();
      const dropdownOpen = ref(false);
      const imageNames = ref([""]);
      let isPlatformSelected = ref(false);
      let platformCost = ref();
      const newProduct: Ref<IProduct> = ref({
        image: null,
        name: "",
        owner: null,
        productfamily: null,
        region: null,
        platform_cost: null,
      });
      const createdProductFlag = ref(false);
      const regions = computed(() => {
        return [...(store.getters.getRegions || [])].sort((a, b) => a.name.localeCompare(b.name));
      });
      const users = computed(() => {
        return (store.getters.getUsers || []).slice().sort((a: IUser, b: IUser) => a.username.localeCompare(b.username));
      });
      const loggedUser = computed(() => store.getters.getCurrentUser);
      const products = computed(() => store.getters.getAllProducts);
      const uploadedImage = computed(() => store.getters.getUploadedImage);
      const uploadedImageId = computed(() => store.getters.getUploadedImageId);
      const selectedImage = ref("");
      const allImages = computed(() => store.getters.getAllProductImages);
      const currencyRate = computed(() => store.getters.getCurrencyRate);

      const productFamilies = computed(() => {
        return (store.getters.getOwnedProductFamilies || []).slice().sort((a: IProductFamily, b: IProductFamily) => a.name.localeCompare(b.name));
      });
      const productAdded = ref(false);
      const submitBtnDisabled = ref(false);
      const inputDisabled = ref(false);
      const selectedFamily = ref("");
      const selectedRegion = ref("");
      const selectOwner = ref("");
      const availableBudget = computed(() => store.getters.getRemainingBudget);
      const createdProductFamily = computed(() => store.getters.getCreatedProductFamily);
      store.dispatch(FETCH_ALL_PRODUCT_IMAGES);

      if (props.preSelectedProductFamily && createdProductFamily.value) {
        selectedFamily.value = createdProductFamily.value.name;
      }

      store.dispatch(FETCH_REGIONS);
      store.dispatch(FETCH_USERS);
      store.dispatch(FETCH_ALL_PRODUCTS);
      store.dispatch(FETCH_OWNED_PRODUCT_FAMILIES);

      onBeforeUnmount(() => {
        store.dispatch(CLEAR_UPLOADED_IMAGE);
        store.commit("setRemainingBudget", null);
      });

      imageNames.value = imageFilenames.map((item) => item);

      const productData: Ref<IProduct> = ref({
        productfamily: null,
        name: "",
        region: null,
        owner: null,
        image: null,
        budget_limit: undefined,
        platform_cost: null,
      });

      const handleCurrentProdFamily = () => {
        const currentProdFamily = productFamilies.value ? productFamilies.value.filter((p: IProductFamily) => p.name === selectedFamily.value) : [];

        if (currentProdFamily.length > 0) {
          productData.value.productfamily = Number(currentProdFamily[0].id);
        }
      };

      const handleSelectFamily = async (event: Event) => {
        const selectedProductFamily = productFamilies.value ? productFamilies.value.filter((p: IProductFamily) => p.name === (event?.target as HTMLSelectElement).value) : [];
        if (selectedProductFamily.length > 0) {
          productData.value.productfamily = Number(selectedProductFamily[0].id);
          await store.dispatch(FETCH_PRODUCT_FAMILY_REMAINING_BUDGET, productData.value.productfamily);
          isPlatformSelected.value = selectedProductFamily[0].name.toLowerCase().includes("platform");
        }
      };

      const handleSelectRegions = (event: Event) => {
        const region = regions.value.filter((p: { name: string }) => p.name == (event?.target as HTMLSelectElement).value);
        productData.value.region = Number(region[0].id);
      };

      const toggleDropdown = () => {
        dropdownOpen.value = !dropdownOpen.value;
      };

      const handleSelectImage = (image: IImage) => {
        productData.value.image = Number(image.id);
        selectedImage.value = image.image_data;
        dropdownOpen.value = false;
      };

      async function submitForm() {
        if (props.preSelectedProductFamily) {
          handleCurrentProdFamily();
        }
        const existingProduct = products.value.find((p: IProduct) => p.name.toLowerCase() === productData.value.name.toLowerCase());
        if (!existingProduct) {
          if (!availableBudget.value || availableBudget.value === null) {
            productData.value.budget_limit = undefined;
          }
          productData.value.owner = Number(selectOwner.value);

          if (isPlatformSelected.value) {
            productData.value.platform_cost = platformCost.value;
          } else {
            productData.value.platform_cost = null;
          }

          newProduct.value = productData.value;
          createdProductFlag.value = true;
          await store.dispatch(CREATE_NEW_PRODUCT, productData.value);
          if (selectedImage.value === "") {
            selectedImage.value = "https://placehold.co/240x140";
          }
          productAdded.value = true;
          submitBtnDisabled.value = true;
          inputDisabled.value = true;
        } else {
          store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Product with the name you provided already exists!");
          productData.value.name = "";
        }
      }

      const handleNew = () => {
        createdProductFlag.value = false;
        productAdded.value = false;
        submitBtnDisabled.value = false;
        inputDisabled.value = false;
        productData.value.owner = null;
        productData.value.image = null;
        productData.value.name = "";
        productData.value.productfamily = null;
        productData.value.budget_limit = null;
        productData.value.region = null;
        selectedFamily.value = "";
        selectedRegion.value = "";
        selectedImage.value = "";
        store.dispatch(FETCH_ALL_PRODUCTS);
      };

      const uploadNewImage = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*";
        fileInput.style.display = "none";

        fileInput.addEventListener("change", handleFileInputChange);

        document.body.appendChild(fileInput);
        fileInput.click();
        document.body.removeChild(fileInput);
      };

      const handleFileInputChange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        if (target.files && target.files.length > 0) {
          const file = target.files[0];
          const fileSizeInMb = file.size / 1024 / 1024;
          if (fileSizeInMb > 0.5) {
            store.dispatch(UPDATE_REQUEST_MESSAGE, "Error: Image size cannot exceed 500kb!");
          } else {
            const reader = new FileReader();
            reader.onload = (e) => {
              if (e.target) {
                const result = e.target.result;
                if (typeof result === "string") {
                  store.dispatch(UPLOAD_NEW_PRODUCT_IMAGE, result);
                } else {
                  console.error("Unexpected result type:", typeof result);
                }
                toggleDropdown();
              } else {
                console.error("Event target is null");
              }
            };
            reader.readAsDataURL(file);
          }
        } else {
          console.error("No file selected or file input element not found");
        }
      };

      watch([uploadedImage], () => {
        selectedImage.value = uploadedImage.value;
        store.dispatch(FETCH_ALL_PRODUCT_IMAGES);
      });

      watch([uploadedImageId], () => {
        productData.value.image = Number(uploadedImageId.value);
      });

      watch([productFamilies], () => {
        if (selectedFamily.value.length > 0) {
          isPlatformSelected.value = selectedFamily.value.toLowerCase().includes("platform");
        }
      });

      const placeholderText = ref(`Available: ${formatNumber((availableBudget.value * currencyRate.value).toFixed(2))}`);

      // Watch to update placeholder text when availableBudget changes
      watch(
        () => availableBudget.value,
        (newBudget) => {
          placeholderText.value = `Available: ${formatNumber((newBudget * currencyRate.value).toFixed(2))}`;
        }
      );

      return {
        availableBudget,
        placeholderText,
        productData,
        handleSelectFamily,
        submitForm,
        handleSelectImage,
        toggleDropdown,
        dropdownOpen,
        imageNames,
        createdProductFlag,
        newProduct,
        props,
        regions,
        handleSelectRegions,
        users,
        loggedUser,
        selectedFamily,
        productAdded,
        submitBtnDisabled,
        handleNew,
        inputDisabled,
        selectedRegion,
        selectOwner,
        productFamilies,
        uploadNewImage,
        uploadedImage,
        allImages,
        selectedImage,
        isPlatformSelected,
        platformCost,
        currencyRate,
        formatNumber,
      };
    },
  });
</script>

<template>
  <div class="v-notifications__wrapper" data-testid="notifications-wrapper">
    <h1>Notifications</h1>
    <div v-if="notifications == null || (notifications && !notifications.length)" data-testid="no-messages">No new notifications...</div>
    <div v-else v-for="notification in filteredNotifications" :key="notification.id" class="v-notifications__content">
      <div class="v-notifications__content-action-row">
        <p
          class="v-notifications__content-title"
          :class="{
            'v-notifications__content-title__read': notification.status !== null,
          }"
          @click="openModal(notification.id, notification.title, notification.message, notification.created)"
          data-testid="notification-title"
        >
          {{ notification.title.length > 20 ? notification.title.slice(0, 20) + "..." : notification.title }}
        </p>

        <img
          class="v-notifications__content-action-row-status"
          :class="notification.status !== null ? 'v-notifications__content-action-row-status' : 'v-notifications__content-action-row-status-new'"
          :src="notification.status !== null ? '/mail-open.svg' : '/mail-new.svg'"
          alt="Notification button read status"
          @click="markNotificationAsSeen(notification.id)"
          data-testid="notification-read-button"
        />

        <img src="/delete.jpg" class="v-notifications__content-action-row-delete" alt="Delete notification button" @click="deleteNotification(notification.id)" data-testid="notification-delete-button" />
      </div>
      <p class="v-notifications__content-message" @click="openModal(notification.id, notification.title, notification.message, notification.created)" data-testid="notification-message">
        {{ notification.message.length > 70 ? notification.message.slice(0, 70) + "..." : notification.message }}
      </p>
      <p class="v-notifications__content-time">
        {{ formatDate(notification.created) }}
      </p>
    </div>
    <C_Pagination v-if="totalPages > 1" :totalPages="totalPages" :itemList="notifications" @paginateList="paginateList" data-testid="pages-indicator" />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from "vue";
  import { useStore } from "vuex";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import C_Pagination from "@/components/C_Pagination.vue";

  export default defineComponent({
    name: "Notification",
    components: {
      C_Pagination,
    },
    setup() {
      const { MARK_NOTIFICATION_AS_READ, DELETE_NOTIFICATION, DEFINE_CURRENT_NOTIFICATION, SHOW_NOTIFICATIONS_MODAL, FETCH_NOTIFICATIONS } = VUEX_ACTIONS;

      const store = useStore();
      const isRead = ref(false);
      const notifications = computed(() => store.getters.getNotifications);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      const filteredNotifications = ref(notifications.value.slice(0, 10));
      const paginatedList = ref(notifications.value.slice(0, 10));
      const totalPages = computed(() => {
        if (notifications.value.length > 10 && notifications.value.length % 10 != 0) {
          return Math.floor(notifications.value.length / 10) + 1;
        } else {
          return notifications.value.length / 10;
        }
      });

      const markNotificationAsSeen = (notificationId: number) => {
        const payload = { id: notificationId, usernameid: loggedUser.value };
        store.dispatch(MARK_NOTIFICATION_AS_READ, payload);
        for (const element of filteredNotifications.value) {
          if (element.id === notificationId) {
            element.status = "Seen";
          }
        }
      };

      const deleteNotification = (notificationId: number) => {
        const payload = { id: notificationId, usernameid: loggedUser.value };
        store.dispatch(DELETE_NOTIFICATION, payload);
        for (const notification of filteredNotifications.value) {
          if (notification.id === notificationId) {
            const index = filteredNotifications.value.indexOf(notification);
            if (index !== -1) {
              filteredNotifications.value.splice(index, 1);
            }
          }
        }
      };

      const openModal = (id: number, title: string, message: string, date: Date) => {
        store.dispatch(SHOW_NOTIFICATIONS_MODAL, true);
        const payload = { id, title, message, date };
        store.dispatch(DEFINE_CURRENT_NOTIFICATION, payload);
        markNotificationAsSeen(id);
        store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      };

      watch([notifications], () => {
        paginatedList.value = notifications.value.slice(0, 10);
        filteredNotifications.value = notifications.value.slice(0, 10);
      });

      const paginateList = (filteredList: Array<any>) => {
        filteredNotifications.value = filteredList;
        paginatedList.value = filteredList;
      };

      return {
        notifications,
        formatDate,
        isRead,
        openModal,
        markNotificationAsSeen,
        deleteNotification,
        filteredNotifications,
        totalPages,
        paginateList,
      };
    },
  });
</script>

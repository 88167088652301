<template>
  <div v-if="dataLoaded && products.length > 0">
    <div class="c-pfcurrentyear-chart__container" v-if="chartData">
      <Bar id="pf-currentyear-chart" :options="chartOptions" :data="chartData" />
      <div class="c-pfcurrentyear-chart__note">*Amounts are shown in {{ currency }}</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Bar } from "vue-chartjs";
  import { computed, defineComponent, ref, watch } from "vue";
  import { IChartData } from "@/utils/interfaces/IChartData";
  import { IProduct } from "@/utils/interfaces/IProductTypes";
  import { calculateProductPOForecast, calculateProductInvoiceActuals } from "@/utils/helpers/calculateProductForecastActuals";
  import { useStore } from "vuex";

  import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from "chart.js";

  ChartJS.register(Tooltip, Title, BarElement, CategoryScale, LinearScale);

  type BarChartOptions = {
    responsive: boolean;
    indexAxis: "x" | "y";
  };

  export default defineComponent({
    name: "PFCurrentYearChart",
    components: { Bar },
    props: {
      products: {
        type: Array as () => IProduct[],
        required: true,
      },
    },
    setup(props) {
      const dataLoaded = ref(false);
      const chartData = ref<IChartData>();
      const store = useStore();
      const currency = computed(() => store.getters.getSelectedCurrency);
      const currencyRate = computed(() => store.getters.getCurrencyRate);

      const updateChartData = () => {
        if (props.products && props.products.length > 0) {
          const datasets = [];

          for (let productIndex = 0; productIndex < props.products.length; productIndex++) {
            const product = props.products[productIndex];

            const product_po_forecast = calculateProductPOForecast(product);
            const product_invoice_actuals = calculateProductInvoiceActuals(product);

            const budget = parseFloat((Number(product.budget || 0) * currencyRate.value).toFixed(2));
            const forecast = parseFloat((Number(product.capitalforecast || 0) * currencyRate.value + Number(product.expensesforecast || 0) * currencyRate.value + Number(product_po_forecast || 0) * currencyRate.value).toFixed(2));
            const actuals = parseFloat((Number(product.capitalactuals || 0) * currencyRate.value + Number(product.expensesactuals || 0) * currencyRate.value + Number(product_invoice_actuals || 0) * currencyRate.value).toFixed(2));

            datasets.push({
              label: product.name,
              data: [budget, forecast, actuals] as number[],
              backgroundColor: [productIndex % 2 === 0 ? "#65ac1e" : "#00793a", productIndex % 2 === 0 ? "#21a0d2" : "#004a96", productIndex % 2 === 0 ? "#FFA41B" : "#FC5404"],
            });
          }

          chartData.value = {
            labels: ["Budget", "Forecast", "Actuals"],
            datasets: datasets,
          };

          dataLoaded.value = true;
        }
      };

      watch([() => props.products], () => {
        updateChartData();
      });

      watch([currencyRate], () => {
        updateChartData();
      });

      const chartOptions = {
        plugins: {
          title: {
            display: true,
            text: "Current year",
            font: {
              family: "customFont",
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
        },
        indexAxis: "x" as const,
      } as BarChartOptions;

      return {
        dataLoaded,
        chartData,
        chartOptions,
        currency,
      };
    },
  });
</script>

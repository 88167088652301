<template>
  <div class="c-table-overview--budget">
    <C_Search @input="filterFutureBudgetPlans($event)" :placeholder="'Search by entity name...'" v-if="futureBudget.length > 0" />
    <div class="c-table-overview__header">
      <div>Entity</div>
      <div>Owner</div>
      <div>Status</div>
      <div>Demand ID</div>
      <div>Demand Title</div>
      <div>Demanded Budget</div>
      <div>Proposed Budget</div>
      <div>Approved Budget</div>
      <div>Details</div>
    </div>
    <div v-if="futureBudget.length > 0" class="c-table-overview__body-row--wrapper">
      <div v-for="(entity, index) in filteredBudget" :key="index">
        <div class="c-table-overview__body-row">
          <div>
            {{ entity.entity_name }}
          </div>
          <div>
            {{ entity.owner }}
          </div>
          <div>{{ entity.status }}</div>
          <div>
            {{ entity.demand_id ? entity.demand_id : "-" }}
          </div>

          <div>
            {{ entity.demand_title ? entity.demand_title : "-" }}
          </div>
          <div>
            {{ entity.demanded_budget ? formatNumber((entity.demanded_budget * currencyRate).toFixed(2)) + " " + currency : "-" }}
          </div>
          <div>
            {{ entity.proposed_budget ? formatNumber((entity.proposed_budget * currencyRate).toFixed(2)) + " " + currency : "-" }}
          </div>
          <div>
            {{ entity.approved_budget ? formatNumber((entity.approved_budget * currencyRate).toFixed(2)) + " " + currency : "-" }}
          </div>
          <div>
            <button @click="handleFutureBudgetDetails(entity)">View</button>
          </div>
        </div>
      </div>
      <div v-if="filteredBudget.length === 0">There are no such entities.</div>
    </div>

    <div v-else class="c-table-overview__body-row--empty">There are no entities with planned future budget.</div>
  </div>
  <C_FutureBudgetModal v-if="showDetailsModal" @close-details-modal="showDetailsModal = !showDetailsModal" :entity="selectedEntity"></C_FutureBudgetModal>
</template>
<script lang="ts" setup>
  import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { computed, ref } from "vue";
  import { useStore } from "vuex";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import C_FutureBudgetModal from "./modals/C_FutureBudgetModal.vue";
  import C_Search from "./C_Search.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  const { FETCH_SPECIFIC_FUTURE_BUDGET } = VUEX_ACTIONS;
  const store = useStore();
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const showDetailsModal = ref(false);
  const selectedEntity = ref<IBudgetPlan>({});
  const futureBudget = computed(() => {
    const budget = store.getters.getFutureBudget;
    return budget.filter((item: IBudgetPlan) => item.status == "approved");
  });
  const filteredBudget = ref(futureBudget.value);
  const handleFutureBudgetDetails = (entity: IBudgetPlan) => {
    showDetailsModal.value = !showDetailsModal.value;
    selectedEntity.value = entity;
    store.dispatch(FETCH_SPECIFIC_FUTURE_BUDGET, selectedEntity.value.id);
  };

  const filterFutureBudgetPlans = (event: Event) => {
    const searchTerm = (event.target as HTMLInputElement).value;

    filteredBudget.value = futureBudget.value.filter((budget: IBudgetPlan) => {
      if (budget?.entity_name) return budget?.entity_name.toLowerCase().includes(searchTerm.toLowerCase().trim());
    });
  };
</script>

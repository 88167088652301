import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "v-filter-products__wrapper",
  "data-testid": "filterproducts-wrapper"
}
const _hoisted_2 = { class: "v-filter-products__filter-wrapper" }
const _hoisted_3 = { class: "v-filter-products__products-wrapper" }
const _hoisted_4 = { class: "v-filter-products__summary-overview" }
const _hoisted_5 = {
  class: "v-filter-products__summary-overview--title",
  "data-testid": "summary-overview"
}
const _hoisted_6 = { "data-testid": "summary-overview-title" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "c-budgetsummary__wrapper--empty",
  "data-testid": "budget-summary-empty"
}
const _hoisted_9 = {
  key: 0,
  class: "v-filter-products__toggle-view"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 0,
  class: "v-filter-products__projectapp-cards"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = {
  key: 0,
  class: "v-filter-products__product-cards"
}
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 3,
  class: "cst-spinner"
}
const _hoisted_17 = {
  key: 4,
  class: "v-filter-products__charts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RequestMessage = _resolveComponent("RequestMessage")!
  const _component_FilterBar = _resolveComponent("FilterBar")!
  const _component_BudgetSummary = _resolveComponent("BudgetSummary")!
  const _component_ProjAppCard = _resolveComponent("ProjAppCard")!
  const _component_TableOverview = _resolveComponent("TableOverview")!
  const _component_ProductCard = _resolveComponent("ProductCard")!
  const _component_PFCurrentYearChart = _resolveComponent("PFCurrentYearChart")!
  const _component_PFLastNextYearChart = _resolveComponent("PFLastNextYearChart")!
  const _component_ProductDetails = _resolveComponent("ProductDetails")!
  const _component_ProjectAppModal = _resolveComponent("ProjectAppModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_RequestMessage),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.filtersLoaded)
          ? (_openBlock(), _createBlock(_component_FilterBar, {
              key: 0,
              header: _ctx.header,
              filterList: _ctx.filters,
              isProduct: true,
              onSubmit: _ctx.handleFilterSubmit
            }, null, 8, ["header", "filterList", "onSubmit"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.productInOverviewName && !_ctx.isPlatform && _ctx.costUnitsEnabled)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "v-filter-products__summary-overview--btn--cu-allocation cst-button cst-button-primary",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCUAllocationPercentageClick && _ctx.handleCUAllocationPercentageClick(...args)))
                }, "CU Allocation Percentage"))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.productInOverviewName ? _ctx.productInOverviewName : _ctx.selectedProductFamily) + " " + _toDisplayString(_ctx.productInOverviewName ? "Product Overview" : "Product Family Overview") + " ", 1),
              (_ctx.isPlatform)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "v-filter-products__summary-overview--btn--consumers cst-button cst-button-primary",
                    type: "button",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleConsumersClick && _ctx.handleConsumersClick(...args)))
                  }, "Consumers"))
                : _createCommentVNode("", true),
              (_ctx.isPlatform && _ctx.canManagePlatform)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "v-filter-products__summary-overview--btn--manage cst-button cst-button-primary",
                    type: "button",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleManageClick && _ctx.handleManageClick(...args)))
                  }, "Manage Platform"))
                : _createCommentVNode("", true)
            ])
          ]),
          (_ctx.areProductsFetched)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(), _createBlock(_component_BudgetSummary, {
                  size: "big",
                  key: _ctx.productFamily.id,
                  budget: _ctx.projAppFetched && _ctx.isProductFocused ? _ctx.projAppTarget : _ctx.totals.totalBUDG,
                  forecast: _ctx.projAppFetched && _ctx.isProductFocused ? _ctx.projAppForecast : _ctx.totals.totalFCST,
                  actuals: _ctx.projAppFetched && _ctx.isProductFocused ? _ctx.projAppActuals : _ctx.totals.totalACT,
                  "data-testid": "budget-summary"
                }, null, 8, ["budget", "forecast", "actuals"]))
              ]))
            : _createCommentVNode("", true),
          (!_ctx.areProductsFetched)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Select a filter on the left side to see the overview."))
            : _createCommentVNode("", true)
        ]),
        (_ctx.areProductsFetched || _ctx.isProductFocused)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("img", {
                src: './grid.svg',
                alt: "grid view",
                style: _normalizeStyle(_ctx.isGridView ? { border: '1px solid #525252' } : ''),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isGridView = !_ctx.isGridView))
              }, null, 4),
              _createElementVNode("img", {
                src: './list.svg',
                alt: "list view",
                style: _normalizeStyle(!_ctx.isGridView ? { border: '1px solid #525252' } : ''),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isGridView = !_ctx.isGridView))
              }, null, 4)
            ]))
          : _createCommentVNode("", true),
        (_ctx.productInOverviewName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_ctx.projectsApplicationsList.length > 0 && _ctx.isGridView)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectsApplicationsList, (projapp) => {
                      return (_openBlock(), _createBlock(_component_ProjAppCard, {
                        key: projapp.id,
                        projapp: projapp,
                        onProjappDetails: _ctx.showProjAppDetails
                      }, null, 8, ["projapp", "onProjappDetails"]))
                    }), 128))
                  ]))
                : (_ctx.projectsApplicationsList.length > 0 && !_ctx.isGridView)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_TableOverview, {
                        onShowProductDetails: _ctx.showProductDetails,
                        onShowProjappDetails: _ctx.showProjAppDetails,
                        focusedproductid: _ctx.focusedProductId,
                        isproductfocused: true
                      }, null, 8, ["onShowProductDetails", "onShowProjappDetails", "focusedproductid"])
                    ]))
                  : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.productInOverviewName && _ctx.areProductsFetched)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              (_ctx.isGridView && _ctx.products.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
                      return (_openBlock(), _createBlock(_component_ProductCard, {
                        key: product.id,
                        product: product,
                        onShowProductDetails: _ctx.showProductDetails,
                        onSelectedProductIdName: _ctx.showProjAppListInProduct
                      }, null, 8, ["product", "onShowProductDetails", "onSelectedProductIdName"]))
                    }), 128))
                  ]))
                : (_ctx.isFilterSubmitted)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_TableOverview, {
                        productlist: _ctx.products,
                        onShowProductDetails: _ctx.showProductDetails,
                        onShowProjappDetails: _ctx.showProjAppDetails
                      }, null, 8, ["productlist", "onShowProductDetails", "onShowProjappDetails"])
                    ]))
                  : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isFilterSubmitted && !_ctx.areProductsFetched)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16))
          : _createCommentVNode("", true),
        (!_ctx.productInOverviewName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createVNode(_component_PFCurrentYearChart, { products: _ctx.products }, null, 8, ["products"]),
              _createVNode(_component_PFLastNextYearChart, { products: _ctx.products }, null, 8, ["products"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ProductDetails, { selectedProductId: _ctx.selectedProductId }, null, 8, ["selectedProductId"])
    ]),
    _createVNode(_component_ProjectAppModal)
  ], 64))
}
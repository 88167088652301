<template>
  <div v-if="showPRDetails" class="c-requestdetails__wrapper" data-testid="requestdetails-wrapper">
    <div class="c-requestdetails__container">
      <div v-if="!dataLoaded" class="cst-spinner"></div>
      <div v-if="dataLoaded">
        <div class="c-requestdetails__container__title">
          <h2>Request details</h2>

          <img src="../../../public/remove-white.svg" alt="Remove list item button" @click="closeModal" />
        </div>

        <div class="c-requestdetails__container__items__wrapper">
          <div class="c-requestdetails__items__column">
            <div v-if="selectedItem?.id" class="c-requestdetails__items__column__row">
              <h3>Request ID:</h3>
              <p>{{ selectedItem.id }}</p>
            </div>
            <div v-if="selectedItem?.prownername" class="c-requestdetails__items__column__row">
              <h3>PR Owner:</h3>
              <p>{{ selectedItem.prownername }}</p>
            </div>

            <div v-if="selectedItem?.podate" class="c-requestdetails__items__column__row">
              <h3>Order Date:</h3>
              <p>{{ formattedOrderDate }}</p>
            </div>
            <div v-if="selectedItem?.ordertext" class="c-requestdetails__items__column__row">
              <h3>Description:</h3>
              <p class="c-requestdetails__items__column--ordertext">
                {{ selectedItem.ordertext }}
              </p>
            </div>
          </div>

          <div class="c-requestdetails__items__column">
            <div v-if="sbuEnabled" class="c-requestdetails__items__column__row">
              <h3>SBU:</h3>
              <p>{{ sbuName }}</p>
            </div>
            <div v-if="selectedItem?.accountingelement && accountingElementEnabled" class="c-requestdetails__items__column__row">
              <h3>Accounting Element:</h3>
              <p>{{ selectedItem.accountingelement }}</p>
            </div>
            <div v-if="selectedItem?.deliverydate" class="c-requestdetails__items__column__row">
              <h3>Delivery Date:</h3>
              <p>{{ formattedDeliveryDate }}</p>
            </div>
          </div>
          <div class="c-requestdetails__items__column">
            <div v-if="selectedItem?.orderamount" class="c-requestdetails__items__column__row">
              <h3>Order Amount:</h3>
              <p>
                {{ formatNumber((selectedItem.orderamount * currencyRate).toFixed(2)) }}
                {{ currency }}
              </p>
            </div>
            <div v-if="selectedItem?.amountCurrentYear" class="c-requestdetails__items__column__row">
              <h3>Current Year:</h3>
              <p>
                {{ formatNumber(Number(selectedItem.amountCurrentYear ? selectedItem.amountCurrentYear : selectedItem.orderamount * currencyRate).toFixed(2)) }}
                {{ currency }}
              </p>
            </div>
            <div v-if="selectedItem?.amountNextYear" class="c-requestdetails__items__column__row">
              <h3>Next Year:</h3>
              <p>
                {{ formatNumber(Number(selectedItem.amountNextYear ? selectedItem.amountNextYear : 0 * currencyRate).toFixed(2)) }}
                {{ currency }}
              </p>
            </div>
          </div>
          <div class="c-requestdetails__items__column">
            <h3>Comment:</h3>
            <div class="c-requestdetails__items__column__row">
              <p class="c-requestdetails__items__column--comment">
                {{ selectedItem?.comments ? selectedItem.comments : "..." }}
              </p>
            </div>
          </div>
        </div>
        <div class="c-requestdetails__container__listitems">
          <div class="c-requestdetails__container__listitems__wrapper">
            <table class="c-requestdetails__container__listitems-table">
              <caption>
                <h3 class="c-requestdetails__container__listitems-title">List Items:</h3>
              </caption>
              <thead>
                <tr class="c-requestdetails__container__listitems-table-head">
                  <th>Position:</th>
                  <th>Item name:</th>
                  <th>Type:</th>
                  <th>Cost type:</th>
                  <th>Resource name:</th>
                  <th>Person days:</th>
                  <th>Amount:</th>
                  <th>Current Year:</th>
                </tr>
              </thead>
              <tbody
                :class="{
                  'c-requestdetails__container__listitems-table__body': sortedItems && sortedItems.length < 2,
                }"
              >
                <tr v-for="(item, index) in sortedItems" class="c-requestdetails__container__listitems-table-row" :key="index">
                  <td>{{ item.position }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.costtype.split(" ")[0] }}</td>
                  <td>{{ item.costtype.split(" ")[1] }}</td>
                  <td>{{ item.resourcename }}</td>
                  <td>
                    {{ item?.quantity && item.quantity > 0 ? item.quantity : "" }}
                  </td>
                  <td>
                    {{ formatNumber((item.amount ? item.amount * currencyRate : 0).toFixed(2)) + " " + currency }}
                  </td>
                  <td>
                    {{ formatNumber(Number(item.amountCurrentYear ? item.amountCurrentYear : item.amount * currencyRate).toFixed(2)) + " " + currency }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div class="c-requestdetails__container__cta">
            <div class="c-requestdetails__container__cta__message" v-if="selectedItem?.message && !showCTA">
              <div class="c-requestdetails__items__column__row">
                <img :src="'./alert.svg'" alt="message alert icon" />
                <h3>Message:</h3>
              </div>
              <div v-if="selectedItem?.message" class="c-requestdetails__items__column__row">
                <p class="c-requestdetails__container__cta__message--box">
                  {{ selectedItem.message }}
                </p>
              </div>
            </div>
            <div v-if="showCTA" data-testid="c-requestdetails-showcta" class="c-requestdetails__container__cta__buttons">
              <button class="cst-button cst-button-primary" @click="acceptRequest" data-testid="c-requestdetails-approvebtn">Approve</button>
              <button class="cst-button cst-button-danger" @click="declineRequest" data-testid="c-requestdetails-declinebtn">Decline</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { IPurchaseRequest } from "../../utils/interfaces/IPurchaseRequest";
  import { formatDate } from "../../utils/helpers/formatDate";
  import { formatNumber } from "../../utils/helpers/formatNumber";
  import { IListItem } from "../../utils/interfaces/IListItem";
  import { VUEX_ACTIONS } from "../../utils/constants";

  export default defineComponent({
    name: "RequestDetails",
    props: {
      selectedItemId: Number,
      showCTA: Boolean,
      selectedPRListItems: {
        type: Array as () => IListItem[],
      },
    },
    methods: {
      declineRequest() {
        this.$emit("decline", {
          itemId: this.selectedItemId,
          message: this.messageInput,
        });
      },
      acceptRequest() {
        this.$emit("approve", {
          itemId: this.selectedItemId,
          message: this.messageInput,
        });
      },
    },
    setup(props) {
      const { SHOW_PR_DETAILS_VIEW } = VUEX_ACTIONS;
      const selectedItem = ref<IPurchaseRequest | null>(null);
      const store = useStore();
      const currency = computed(() => store.getters.getSelectedCurrency);
      const currencyRate = computed(() => store.getters.getCurrencyRate);
      const requests = computed(() => store.getters.getPurchaseRequests);
      const archive = computed(() => store.getters.getArchive);
      const combinedData = computed(() => [...requests.value, ...archive.value]);

      const sbus = computed(() => store.getters.getSBU);
      const messageInput = ref<HTMLInputElement | null>(null);
      const dataLoaded = ref(false);
      const showPRDetails = computed(() => store.getters.getShowPRDetails);
      const appConfiguration = computed(() => store.getters.getAppConfiguration);
      const sbuEnabled = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.pr_sbu_enabled;
        } else {
          return false;
        }
      });
      const accountingElementEnabled = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.pr_accounting_element;
        } else {
          return false;
        }
      });

      const sortedItems = ref<IListItem[]>([]);

      // Needed to mount modal with details
      onMounted(() => {
        if (showPRDetails.value) {
          selectedItem.value = combinedData.value.find((item: IPurchaseRequest) => Number(item.id) === props.selectedItemId) || null;
          dataLoaded.value = true;
        }
        if (props.selectedPRListItems) {
          sortedItems.value = [...props.selectedPRListItems].sort((a, b) => a.position - b.position);
        }
      });

      const closeModal = () => {
        store.dispatch(SHOW_PR_DETAILS_VIEW, false);
      };

      const sbuName = computed(() => {
        if (selectedItem.value && sbus.value) {
          const matchingSbu = sbus.value.find((sbu: { id: number; name: string }) => sbu.id === selectedItem.value?.sbu);
          return matchingSbu ? matchingSbu.name : "";
        }
        return "";
      });

      const formattedDeliveryDate = computed(() => {
        return formatDate(selectedItem.value?.deliverydate ?? "");
      });

      const formattedOrderDate = computed(() => {
        return formatDate(selectedItem.value?.podate ?? "");
      });

      //Needed for archive pr details
      watch(
        () => props.selectedItemId,
        (newSelectedItemId) => {
          selectedItem.value = combinedData.value.find((item: IPurchaseRequest) => Number(item.id) === newSelectedItemId) || null;
          dataLoaded.value = true;
        }
      );

      // Loads data for first item faster
      watch([combinedData], () => {
        selectedItem.value = combinedData.value.find((item: IPurchaseRequest) => Number(item.id) === props.selectedItemId) || null;

        dataLoaded.value = true;
      });

      watch(
        () => props.selectedPRListItems,
        (newItems) => {
          if (newItems) {
            // Sort selectedPRListItems by position in ascending order
            sortedItems.value = [...newItems].sort((a, b) => a.position - b.position);
          }
        }
      );

      return {
        selectedItem,
        closeModal,
        formatNumber,
        currency,
        sbuName,
        formattedDeliveryDate,
        formattedOrderDate,
        combinedData,
        messageInput,
        dataLoaded,
        showPRDetails,
        currencyRate,
        sbuEnabled,
        accountingElementEnabled,
        sortedItems,
      };
    },
  });
</script>

export enum VUEX_ACTIONS {
  // APPLICATIONS
  FETCH_APP = "fetchApplications",
  FETCH_APP_IN_PRODUCT = "fetchApplicationsInProduct",
  CREATE_NEW_APP = "createNewApplication",
  UPDATE_APP = "updateApplication",
  FETCH_APPLICATIONS_IN_PLATFORM = "fetchApplicationsInPlatform",

  //ARCHIVE
  FETCH_ARCHIVE = "fetchArchive",
  DELETE_FROM_ARCHIVE = "deleteRequestFromArchive",
  RESTORE_FROM_ARCHIVE = "restoreRequest",

  //COST UNITS
  FETCH_COST_UNITS = "fetchCostUnits",
  FETCH_FILTERED_COST_UNITS = "fetchFilteredCostUnits",
  CLEAR_FILTERED_COST_UNITS = "clearFilteredCostUnits",
  FETCH_NEW_COST_UNIT_PRODUCTS = "fetchNewCostUnitProducts",
  UPDATE_COST_UNIT = "updateCostUnit",
  CREATE_NEW_COST_UNIT = "createNewCostUnit",
  ADD_PRODUCT_TO_COST_UNIT = "addProductToCostUnit",
  FETCH_ALL_PRODUCTS_IN_COST_UNIT = "fetchAllProductsForCostUnit",
  FETCH_REMAINING_PRODUCT_PERCENTAGE = "fetchRemainingProductPercentage",
  UPDATE_PRODUCT_LIST = "updateProductList",
  FETCH_COST_UNIT_PRODUCTS = "fetchCostUnitProducts",
  DELETE_PRODUCT_FROM_COST_UNIT = "deleteProductFromCostUnit",

  //CURRENCY
  UPDATE_SELECTED_CURRENCY = "updateSelectedCurrency",
  FETCH_CURRENCIES = "fetchCurrencies",

  //CURRENT USER
  UPDATE_CURRENT_USER = "updateCurrentUser",
  UPDATE_CURRENT_USER_ROLES = "updateCurrentUserRoles",
  UPDATE_CURRENT_USER_GROUPS = "updateCurrentUserGroups",
  DOWNLOAD_LOGS = "downloadLogs",

  //CONFIGURATION
  FETCH_CONFIGURATION = "fetchConfiguration",
  FETCH_APP_VERSION = "fetchAppVersion",

  //TOKEN
  UPDATE_TOKEN = "updateToken",
  UPDATE_REFRESH_TOKEN = "updateRefreshToken",

  //FILTERS
  FETCH_FILTERS = "fetchFilters",
  FETCH_COST_UNIT_FILTERS = "fetchCostUnitFilters",
  ARE_PRODUCTS_FETCHED = "setAreProductsFetched",

  //INVOICES
  FETCH_INVOICES = "fetchInvoices",
  FETCH_PROJECT_INVOICES = "fetchProjectInvoices",
  FETCH_APPLICATION_INVOICES = "fetchApplicationInvoices",
  DOWNLOAD_PDF = "downloadPDF",

  //LIST ITEMS
  FETCH_LIST_ITEMS = "fetchListItems",
  CREATE_LIST_ITEM = "createListItem",
  ASSIGN_COST_TYPE = "assignCostType",
  DELETE_LIST_ITEM = "deleteListItem",

  //MODALS
  CHANGE_PRODUCT_DETAILS_VIEW = "changeProductDetailsView",
  CHANGE_PO_DETAILS_VIEW = "changePODetailsView",
  SHOW_PR_DETAILS_VIEW = "changePRDetailsView",
  CHANGE_CONFIRM_MODAL_VIEW = "changeConfirmModalView",
  SHOW_NOTIFICATIONS_MODAL = "showNotificationModalView",
  SHOW_EXPORT_DOCUMENT_MODAL = "showExportDocumentModal",
  CHANGE_JUSTIFICATION_MODAL_VIEW = "changeJustificationModalView",

  //NOTIFICATIONS
  FETCH_NOTIFICATIONS = "fetchNotifications",
  MARK_NOTIFICATION_AS_READ = "markNotificationAsRead",
  DELETE_NOTIFICATION = "deleteNotification",
  DEFINE_CURRENT_NOTIFICATION = "defineCurrentNotification",
  SHOW_NOTIFICATIONS_DROPDOWN = "showNotificationsDropdown",

  //PRODUCT FAMILY
  FETCH_PRODUCT_FAMILY = "fetchProductFamily",
  FETCH_PRODUCT_FAMILY_REMAINING_BUDGET = "fetchProductFamilyRemainingBudget",
  FETCH_ALL_PRODUCT_FAMILIES = "fetchAllProductFamilies",
  CREATE_NEW_PRODUCT_FAMILY = "createNewProductFamily",
  FETCH_OWNED_PRODUCT_FAMILIES = "fetchOwnedProductFamilies",
  FETCH_RELATED_PRODUCT_FAMILIES = "fetchRelatedProductFamilies",
  FETCH_OWNED_PRODUCT_FAMILIES_BUDGET_PLANNING = "fetchOwnedProductFamiliesBudgetPlanning",

  //PRODUCT FILTERING
  FETCH_PRODUCT_FILTERS = "fetchProductFilters",
  CLEAR_FILTERED_PRODUCTS = "clearFilteredProducts",

  //PRODUCTS
  FETCH_SINGLE_PRODUCT = "fetchSingleProduct",
  FETCH_ALL_PRODUCTS = "fetchAllProducts",
  FETCH_PRODUCT_AVAILABLE_BUDGET = "fetchProductAvailableBudget",
  FETCH_PRODUCTS_IN_PROD_FAMILY = "fetchProductsInProductFamily",
  CREATE_NEW_PRODUCT = "createNewProduct",
  UPDATE_PRODUCT_PERCENTAGE_IN_CU = "updateProductBudgetInCostUnit",
  FETCH_OWNED_PRODUCTS = "fetchOwnedProducts",
  FETCH_PRODUCT_CU_ALLOCATION = "fetchProductCuAllocation",
  UPDATE_PRODUCT_CU_ALLOCATED_STATE = "changeProductCUAllocationState",

  //PROJECTS
  FETCH_PROJECTS = "fetchProjects",
  FETCH_PROJECTS_IN_PRODUCT = "fetchProjectsInProduct",
  CREATE_NEW_PROJECT = "createNewProject",
  UPDATE_PROJECT = "updateProject",
  FETCH_PROJECTS_IN_PLATFORM = "fetchProjectsInPlatform",
  FETCH_OWNED_PROJECTS = "fetchOwnedProjects",

  //PURCHASE ORDERS
  FETCH_PURCHASE_ORDERS = "fetchPurchaseOrders",
  FETCH_APPLICATION_PURCHASE_ORDERS = "fetchApplicationPurchaseOrders",
  FETCH_PROJECT_PURCHASE_ORDERS = "fetchProjectPurchaseOrders",
  UPDATE_PO_LIST_FETCHED_STATE = "changeIsPOListFetchedState",
  FETCH_INVOICES_FROM_PO = "fetchInvoicesFromPO",
  FETCH_OWNED_APPLICATIONS = "fetchOwnedApplications",
  FETCH_PRODUCT_PURCHASE_ORDERS = "fetchProductPurchaseOrders",

  //PURCHASE REQUESTS
  FETCH_PURCHASE_REQUESTS = "fetchPurchaseRequests",
  CREATE_PURCHASE_REQUEST = "createPurchaseRequest",
  UPDATE_PURCHASE_REQUEST = "updatePurchaseRequest",
  DECLINE_PURCHASE_REQUEST = "declinePurchaseRequest",
  SWITCH_TO_PURCHASE_ORDER = "switchToPurchaseOrder",
  CHANGE_REQUEST_ENTITIES = "changeRequestEntities",
  CHANGE_PURCHASE_REQUEST_STATUS = "updatePurchaseRequestStatus",
  CREATE_PURCHASE_REQUEST_FROM_EDIT = "createPurchaseRequestFromEdit",
  DELETE_PURCHASE_REQUEST = "deletePurchaseRequest",

  //REGIONS
  FETCH_REGIONS = "fetchRegions",

  //REQUEST MESSAGE
  UPDATE_REQUEST_MESSAGE = "updateRequestMessage",
  UPDATE_IS_VISIBLE_REQUEST_MESSAGE = "setIsVisibleRequestMessage",

  //SBU
  FETCH_SBU = "fetchSBU",

  //SUPPLIERS
  FETCH_SUPPLIERS = "fetchSuppliers",

  //USERS
  FETCH_USERS = "fetchUsers",
  CHANGE_USER_DROPDOWN = "changeUserDropdownView",

  //IMAGES
  FETCH_UPLOADED_IMAGE = "fetchUploadedImage",
  FETCH_ALL_PRODUCT_IMAGES = "fetchAllProductImages",
  CLEAR_UPLOADED_IMAGE = "clearUploadedImage",
  UPLOAD_NEW_PRODUCT_IMAGE = "uploadNewProductImage",

  //PLATFORM
  CREATE_PLATFORM = "createPlatform",
  FETCH_ALL_PLATFORMS = "fetchAllPlatforms",
  FETCH_PRODUCTS_IN_PLATFORM = "fetchProductsInPlatform",
  ADD_PROJECT_TO_PLATFORM = "addProjectToPlatform",
  ADD_APPLICATION_TO_PLATFORM = "addApplicationToPlatform",
  UPDATE_PLATFORM_COST = "updatePlatformCost",

  //FUTURE BUDGET
  CREATE_FUTURE_BUDGET_PLAN = "createFutureBudgetPlan",
  FETCH_FUTURE_BUDGETS = "fetchFutureBudget",
  FETCH_SPECIFIC_FUTURE_BUDGET = "fetchSpecificFutureBudget",
  CREATE_FUTURE_BUDGET_COMMENT = "createBudgetPlanComment",
  APPROVE_FUTURE_BUDGET_PLAN = "approveFutureBudgetPlan",
  DECLINE_FUTURE_BUDGET_PLAN = "declineFutureBudgetPlan",
}

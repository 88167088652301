import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers } from "vue"
import _imports_0 from '../../public/remove.svg'


const _hoisted_1 = {
  key: 0,
  class: "v-createinvoice",
  "data-testid": "create-invoice"
}
const _hoisted_2 = { class: "cst-inputbox cst-inputbox__choose-items" }
const _hoisted_3 = { class: "cst-inputbox__invoice-item" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "v-createinvoice__li-container" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 1,
  for: "quantity"
}
const _hoisted_8 = { class: "v-createinvoice__available-amount" }
const _hoisted_9 = { class: "v-createinvoice__button-wrapper" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "v-createinvoice__invoice-wrapper" }
const _hoisted_13 = { class: "v-createinvoice__invoice-date" }
const _hoisted_14 = ["min"]
const _hoisted_15 = { class: "v-createinvoice__invoice-title" }
const _hoisted_16 = { class: "v-createinvoice__table-content" }
const _hoisted_17 = { class: "v-createinvoice__table-row" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "v-createinvoice__totalamount" }
const _hoisted_20 = { class: "v-createinvoice__button-wrapper" }
const _hoisted_21 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.createInvoiceEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
            _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Choose list items")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _cache[10] || (_cache[10] = _createElementVNode("label", {
                for: "listItems",
                "data-testid": "list-item-label"
              }, [
                _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                _createTextVNode("List Item:")
              ], -1)),
              _withDirectives(_createElementVNode("select", {
                class: "cst-select-field cst-select-field--list-item",
                id: "listItems",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItemId) = $event)),
                onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleSelectListItem && _ctx.handleSelectListItem(...args))),
                "data-testid": "list-items-select"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localOrderListItems, (listItem) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: listItem.id,
                    value: listItem.id,
                    "data-testid": "list-item-option"
                  }, _toDisplayString(listItem.name) + " - " + _toDisplayString(listItem.costtype), 9, _hoisted_4))
                }), 128))
              ], 544), [
                [_vModelSelect, _ctx.selectedItemId]
              ])
            ]),
            (_ctx.selectedItem && _ctx.showSelectedItemBlock)
              ? (_openBlock(), _createElementBlock("form", {
                  key: 0,
                  onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleAddToInvoice && _ctx.handleAddToInvoice(...args)), ["prevent"])),
                  "data-testid": "add-to-invoice-form"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    (_ctx.selectedItem.resourcename?.length > 0)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                          _cache[11] || (_cache[11] = _createElementVNode("span", null, "Resource Name: ", -1)),
                          _createTextVNode(_toDisplayString(_ctx.selectedItem.resourcename), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem.resourcename?.length > 0)
                      ? (_openBlock(), _createElementBlock("label", _hoisted_7, _cache[12] || (_cache[12] = [
                          _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* ", -1),
                          _createTextVNode("Person days:")
                        ])))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem.resourcename?.length > 0)
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 2,
                          class: "cst-input",
                          type: "number",
                          id: "quantity",
                          "data-testid": "person-days-input",
                          required: "",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.invoiceListItemData.quantity) = $event))
                        }, null, 512)), [
                          [_vModelText, _ctx.invoiceListItemData.quantity]
                        ])
                      : _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                      _cache[13] || (_cache[13] = _createElementVNode("label", { for: "amount" }, [
                        _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                        _createTextVNode("Amount:")
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "cst-input",
                        type: "number",
                        id: "amount",
                        "data-testid": "amount-input",
                        required: "",
                        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.preventDotInInput && _ctx.preventDotInInput(...args))),
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.invoiceListItemData.amount) = $event))
                      }, null, 544), [
                        [_vModelText, _ctx.invoiceListItemData.amount]
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[14] || (_cache[14] = _createElementVNode("p", null, "Available amount:", -1)),
                      _createElementVNode("span", null, _toDisplayString(_ctx.selectedItem.amount + " " + _ctx.selectedItem.currency), 1)
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("button", {
                        type: "submit",
                        class: "submit-button cst-button cst-button-primary",
                        disabled: _ctx.isButtonDisabled,
                        "data-testid": "add-to-invoice-button"
                      }, "Add to Invoice", 8, _hoisted_10)
                    ])
                  ])
                ], 32))
              : (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[15] || (_cache[15] = [
                  _createElementVNode("p", null, "Please choose the list item you want to add to the invoice.", -1)
                ])))
          ])
        ]),
        _createElementVNode("form", {
          class: "cst-inputbox cst-inputbox__create-invoice",
          onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createInvoice && _ctx.createInvoice(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", null, [
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
              _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Create Invoice")
            ], -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", null, [
                _cache[17] || (_cache[17] = _createElementVNode("span", null, "Order name: ", -1)),
                _createTextVNode(_toDisplayString(_ctx.createInvoiceFromOrder.ordertext), 1)
              ]),
              _createElementVNode("p", null, [
                _cache[18] || (_cache[18] = _createElementVNode("span", null, "Customer contact: ", -1)),
                _createTextVNode(_toDisplayString(_ctx.createInvoiceFromOrder.customercontact), 1)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[19] || (_cache[19] = _createElementVNode("label", { for: "date" }, [
                  _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                  _createTextVNode(" Invoice Date:")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "date",
                  id: "date",
                  "data-testid": "invoice-date-input",
                  required: "",
                  min: _ctx.currentDate,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedDate) = $event))
                }, null, 8, _hoisted_14), [
                  [_vModelText, _ctx.selectedDate]
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _cache[20] || (_cache[20] = _createElementVNode("label", { for: "title" }, [
                  _createElementVNode("span", { class: "cst-input-field--mandatory" }, "* "),
                  _createTextVNode("Invoice Title:")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "text",
                  id: "title",
                  required: "",
                  onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.preventDotInInput && _ctx.preventDotInInput(...args))),
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.invoiceTitle) = $event)),
                  "data-testid": "invoice-title-input"
                }, null, 544), [
                  [_vModelText, _ctx.invoiceTitle]
                ])
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "v-createinvoice__table-header" }, [
                _createElementVNode("p", null, "No."),
                _createElementVNode("p", null, "Name"),
                _createElementVNode("p", null, "Resource"),
                _createElementVNode("p", null, "Person Days"),
                _createElementVNode("p", null, "Amount"),
                _createElementVNode("p", null, "Remove")
              ], -1)),
              _createElementVNode("div", _hoisted_16, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItemsFromOrder, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.li_name
                  }, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("p", null, _toDisplayString(index + 1), 1),
                      _createElementVNode("p", null, _toDisplayString(item.li_name), 1),
                      _createElementVNode("p", null, _toDisplayString(item.resource_name), 1),
                      _createElementVNode("p", null, _toDisplayString(item.quantity), 1),
                      _createElementVNode("p", null, _toDisplayString(item.amount + " " + item.currency), 1),
                      _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: "",
                          "data-testid": "remove-invoice-item",
                          onClick: ($event: any) => (_ctx.removeListItemFromInvoice(item.po_listitem_id))
                        }, null, 8, _hoisted_18)
                      ])
                    ])
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("p", null, [
                  _cache[21] || (_cache[21] = _createElementVNode("span", null, "Total Amount:", -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.totalInvoiceAmount), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("button", {
              type: "submit",
              class: "submit-button cst-button cst-button-primary",
              "data-testid": "create-invoice-btn",
              disabled: _ctx.listItemsFromOrder.length < 1
            }, "Create Invoice", 8, _hoisted_21)
          ])
        ], 32)
      ]))
    : _createCommentVNode("", true)
}
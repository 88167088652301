import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "c-budgetcard__wrapper",
  "data-testid": "budgetcard-wrapper"
}
const _hoisted_2 = {
  class: "c-budgetcard__product-name",
  "data-testid": "budgetcard-name"
}
const _hoisted_3 = { class: "c-budgetcard__owner-wrapper" }
const _hoisted_4 = { "data-testid": "budgetcard-owner" }
const _hoisted_5 = { class: "c-budgetcard__summary" }
const _hoisted_6 = { class: "c-budgetcard__row" }
const _hoisted_7 = {
  class: "c-budgetcard__row--value",
  "data-testid": "budgetcard-demand-id"
}
const _hoisted_8 = { class: "c-budgetcard__row" }
const _hoisted_9 = {
  class: "c-budgetcard__row--value",
  "data-testid": "budgetcard-demand-title"
}
const _hoisted_10 = { class: "c-budgetcard__summary" }
const _hoisted_11 = { class: "c-budgetcard__row" }
const _hoisted_12 = {
  class: "c-budgetcard__row--value",
  "data-testid": "budgetcard-demand-value"
}
const _hoisted_13 = { class: "c-budgetcard__row" }
const _hoisted_14 = {
  class: "c-budgetcard__row--value",
  "data-testid": "budgetcard-proposed-value"
}
const _hoisted_15 = { class: "c-budgetcard__row" }
const _hoisted_16 = {
  class: "c-budgetcard__row--value",
  "data-testid": "budgetcard-approved-value"
}
const _hoisted_17 = { class: "c-budgetcard__button--wrapper" }

import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { computed } from "vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_BudgetCard',
  props: {
    entity: {},
    isoverview: { type: Boolean }
  },
  setup(__props: any) {

  const store = useStore();
  const router = useRouter();
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currentUser = computed(() => store.getters.getCurrentUser);

  const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.entity?.entity_name ? _ctx.entity.entity_name : "Future Budget"), 1),
    _createElementVNode("p", {
      class: _normalizeClass(["c-budgetcard__type", {
        'c-budgetcard__type--review': _ctx.entity?.status === 'proposed',
        'c-budgetcard__type--declined': _ctx.entity?.status === 'declined',
        'c-budgetcard__type--approved': _ctx.entity?.status === 'approved',
      }]),
      "data-testid": "budgetcard-status"
    }, _toDisplayString(_ctx.entity?.status ? _ctx.entity.status : "Proposed"), 3),
    _createElementVNode("div", _hoisted_3, [
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "c-budgetcard__owner-label" }, "Owner:", -1)),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.entity?.owner ? _ctx.entity.owner : "-"), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "c-budgetcard__row" }, [
        _createElementVNode("div", { class: "section__line-with-text" }, [
          _createElementVNode("p", { class: "section__title" }, "Demanded")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "c-budgetcard__row--placeholder" }, "Demand ID", -1)),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.entity?.demand_id ? _ctx.entity.demand_id : "-"), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[5] || (_cache[5] = _createElementVNode("p", { class: "c-budgetcard__row--placeholder" }, "Demand Title", -1)),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.entity?.demand_title ? _ctx.entity.demand_title : "-"), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "c-budgetcard__row--placeholder" }, "Demanded Budget", -1)),
        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.entity?.demanded_budget ? _unref(formatNumber)((Number(_ctx.entity.demanded_budget) * currencyRate.value).toFixed(2)) + " " + currency.value : "- EUR"), 1)
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "c-budgetcard__row" }, [
        _createElementVNode("div", { class: "section__line-with-text" }, [
          _createElementVNode("p", { class: "section__title" }, "Proposed")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_13, [
        _cache[8] || (_cache[8] = _createElementVNode("p", { class: "c-budgetcard__row--placeholder" }, "Proposed Budget", -1)),
        _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.entity?.proposed_budget ? _unref(formatNumber)((Number(_ctx.entity.proposed_budget) * currencyRate.value).toFixed(2)) + " " + currency.value : "- EUR"), 1)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "c-budgetcard__row--placeholder" }, "Approved Budget", -1)),
        _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.entity?.approved_budget ? _unref(formatNumber)((Number(_ctx.entity.approved_budget) * currencyRate.value).toFixed(2)) + " " + currency.value : "- EUR"), 1)
      ]),
      _createElementVNode("div", _hoisted_17, [
        (!props.isoverview && _unref(router).currentRoute.value.path.toLowerCase() == '/budget-proposal' && _ctx.entity?.proposed_by == currentUser.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "cst-button-primary c-productcard__button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('future-budget-approve', _ctx.entity))),
              "data-testid": "budgetcard-btn-approve"
            }, " Approve "))
          : _createCommentVNode("", true),
        (!props.isoverview && _unref(router).currentRoute.value.path.toLowerCase() == '/budget-proposal' && _ctx.entity?.proposed_by == currentUser.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: "cst-button-primary c-productcard__button",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('future-budget-decline', _ctx.entity))),
              "data-testid": "budgetcard-btn-decline"
            }, " Decline "))
          : _createCommentVNode("", true),
        _createElementVNode("button", {
          type: "button",
          class: "cst-button-primary c-productcard__button c-budgetcard__button--details",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('future-budget-details', _ctx.entity))),
          "data-testid": "budgetcard-btn-details"
        }, "Details")
      ])
    ])
  ]))
}
}

})
<template>
  <div v-if="dataLoaded && products.length > 0">
    <div class="c-pflastnextyear-chart__container" v-if="chartData">
      <Bar id="pf-currentyear-chart" :options="chartOptions" :data="chartData" />
      <div class="c-pflastnextyear-chart__note">*Amounts are shown in {{ currency }}</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Bar } from "vue-chartjs";
  import { defineComponent, ref, watch, computed } from "vue";
  import { IChartData } from "@/utils/interfaces/IChartData";
  import { IProduct } from "@/utils/interfaces/IProductTypes";
  import { useStore } from "vuex";

  import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from "chart.js";

  ChartJS.register(Tooltip, Title, BarElement, CategoryScale, LinearScale);

  type BarChartOptions = {
    responsive: boolean;
    indexAxis: "x" | "y";
  };

  export default defineComponent({
    name: "PFLastNextYearChart",
    components: { Bar },
    props: {
      products: {
        type: Array as () => IProduct[],
        required: true,
      },
    },
    setup(props) {
      const dataLoaded = ref(false);
      const chartData = ref<IChartData>();
      const store = useStore();
      const currency = computed(() => store.getters.getSelectedCurrency);
      const currencyRate = computed(() => store.getters.getCurrencyRate);

      const updateChartData = () => {
        if (props.products && props.products.length > 0) {
          const datasets = [];

          for (let productIndex = 0; productIndex < props.products.length; productIndex++) {
            const product = props.products[productIndex];
            datasets.push({
              label: product.name,
              data: [
                Number(product.lastyearbudget ? (product.lastyearbudget * currencyRate.value).toFixed(2) : product.lastyearbudget),
                Number(product.budget ? (product.budget * currencyRate.value).toFixed(2) : product.budget),
                Number(product.totalnextyearbudget ? (product.totalnextyearbudget * currencyRate.value).toFixed(2) : product.totalnextyearbudget),
              ] as number[],
              backgroundColor: [productIndex % 2 === 0 ? "#65ac1e" : "#00793a", productIndex % 2 === 0 ? "#21a0d2" : "#004a96", productIndex % 2 === 0 ? "#FFA41B" : "#FC5404"],
            });
          }

          chartData.value = {
            labels: ["Last Year", "Current Year", "Next Year"],
            datasets: datasets,
          };

          dataLoaded.value = true;
        }
      };

      watch([() => props.products], () => {
        updateChartData();
      });

      watch([currencyRate], () => {
        updateChartData();
      });

      const chartOptions = {
        plugins: {
          title: {
            display: true,
            text: "Budget summary",
            font: {
              family: "customFont",
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                family: "customFont",
              },
            },
          },
        },
        indexAxis: "y" as const,
      } as BarChartOptions;

      return {
        dataLoaded,
        chartData,
        chartOptions,
        currency,
      };
    },
  });
</script>

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../public/remove-white.svg'


const _hoisted_1 = {
  key: 0,
  class: "c-requestdetails__wrapper",
  "data-testid": "requestdetails-wrapper"
}
const _hoisted_2 = { class: "c-requestdetails__container" }
const _hoisted_3 = {
  key: 0,
  class: "cst-spinner"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "c-requestdetails__container__title" }
const _hoisted_6 = { class: "c-requestdetails__container__items__wrapper" }
const _hoisted_7 = { class: "c-requestdetails__items__column" }
const _hoisted_8 = {
  key: 0,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_9 = {
  key: 1,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_10 = {
  key: 2,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_11 = {
  key: 3,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_12 = { class: "c-requestdetails__items__column--ordertext" }
const _hoisted_13 = { class: "c-requestdetails__items__column" }
const _hoisted_14 = {
  key: 0,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_15 = {
  key: 1,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_16 = {
  key: 2,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_17 = { class: "c-requestdetails__items__column" }
const _hoisted_18 = {
  key: 0,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_19 = {
  key: 1,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_20 = {
  key: 2,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_21 = { class: "c-requestdetails__items__column" }
const _hoisted_22 = { class: "c-requestdetails__items__column__row" }
const _hoisted_23 = { class: "c-requestdetails__items__column--comment" }
const _hoisted_24 = { class: "c-requestdetails__container__listitems" }
const _hoisted_25 = { class: "c-requestdetails__container__listitems__wrapper" }
const _hoisted_26 = { class: "c-requestdetails__container__listitems-table" }
const _hoisted_27 = { class: "c-requestdetails__container__cta" }
const _hoisted_28 = {
  key: 0,
  class: "c-requestdetails__container__cta__message"
}
const _hoisted_29 = {
  key: 0,
  class: "c-requestdetails__items__column__row"
}
const _hoisted_30 = { class: "c-requestdetails__container__cta__message--box" }
const _hoisted_31 = {
  key: 1,
  "data-testid": "c-requestdetails-showcta",
  class: "c-requestdetails__container__cta__buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showPRDetails)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.dataLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.dataLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[3] || (_cache[3] = _createElementVNode("h2", null, "Request details", -1)),
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: "Remove list item button",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.selectedItem?.id)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Request ID:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem.id), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem?.prownername)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _cache[5] || (_cache[5] = _createElementVNode("h3", null, "PR Owner:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem.prownername), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem?.podate)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Order Date:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.formattedOrderDate), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem?.ordertext)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Description:", -1)),
                          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.selectedItem.ordertext), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    (_ctx.sbuEnabled)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _cache[8] || (_cache[8] = _createElementVNode("h3", null, "SBU:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.sbuName), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem?.accountingelement && _ctx.accountingElementEnabled)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Accounting Element:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.selectedItem.accountingelement), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem?.deliverydate)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                          _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Delivery Date:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.formattedDeliveryDate), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    (_ctx.selectedItem?.orderamount)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _cache[11] || (_cache[11] = _createElementVNode("h3", null, "Order Amount:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.formatNumber((_ctx.selectedItem.orderamount * _ctx.currencyRate).toFixed(2))) + " " + _toDisplayString(_ctx.currency), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem?.amountCurrentYear)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          _cache[12] || (_cache[12] = _createElementVNode("h3", null, "Current Year:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.formatNumber(Number(_ctx.selectedItem.amountCurrentYear ? _ctx.selectedItem.amountCurrentYear : _ctx.selectedItem.orderamount * _ctx.currencyRate).toFixed(2))) + " " + _toDisplayString(_ctx.currency), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.selectedItem?.amountNextYear)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _cache[13] || (_cache[13] = _createElementVNode("h3", null, "Next Year:", -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.formatNumber(Number(_ctx.selectedItem.amountNextYear ? _ctx.selectedItem.amountNextYear : 0 * _ctx.currencyRate).toFixed(2))) + " " + _toDisplayString(_ctx.currency), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[14] || (_cache[14] = _createElementVNode("h3", null, "Comment:", -1)),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.selectedItem?.comments ? _ctx.selectedItem.comments : "..."), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("table", _hoisted_26, [
                      _cache[15] || (_cache[15] = _createElementVNode("caption", null, [
                        _createElementVNode("h3", { class: "c-requestdetails__container__listitems-title" }, "List Items:")
                      ], -1)),
                      _cache[16] || (_cache[16] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", { class: "c-requestdetails__container__listitems-table-head" }, [
                          _createElementVNode("th", null, "Position:"),
                          _createElementVNode("th", null, "Item name:"),
                          _createElementVNode("th", null, "Type:"),
                          _createElementVNode("th", null, "Cost type:"),
                          _createElementVNode("th", null, "Resource name:"),
                          _createElementVNode("th", null, "Person days:"),
                          _createElementVNode("th", null, "Amount:"),
                          _createElementVNode("th", null, "Current Year:")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", {
                        class: _normalizeClass({
                  'c-requestdetails__container__listitems-table__body': _ctx.sortedItems && _ctx.sortedItems.length < 2,
                })
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedItems, (item, index) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            class: "c-requestdetails__container__listitems-table-row",
                            key: index
                          }, [
                            _createElementVNode("td", null, _toDisplayString(item.position), 1),
                            _createElementVNode("td", null, _toDisplayString(item.name), 1),
                            _createElementVNode("td", null, _toDisplayString(item.costtype.split(" ")[0]), 1),
                            _createElementVNode("td", null, _toDisplayString(item.costtype.split(" ")[1]), 1),
                            _createElementVNode("td", null, _toDisplayString(item.resourcename), 1),
                            _createElementVNode("td", null, _toDisplayString(item?.quantity && item.quantity > 0 ? item.quantity : ""), 1),
                            _createElementVNode("td", null, _toDisplayString(_ctx.formatNumber((item.amount ? item.amount * _ctx.currencyRate : 0).toFixed(2)) + " " + _ctx.currency), 1),
                            _createElementVNode("td", null, _toDisplayString(_ctx.formatNumber(Number(item.amountCurrentYear ? item.amountCurrentYear : item.amount * _ctx.currencyRate).toFixed(2)) + " " + _ctx.currency), 1)
                          ]))
                        }), 128))
                      ], 2)
                    ])
                  ])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_27, [
                    (_ctx.selectedItem?.message && !_ctx.showCTA)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "c-requestdetails__items__column__row" }, [
                            _createElementVNode("img", {
                              src: './alert.svg',
                              alt: "message alert icon"
                            }),
                            _createElementVNode("h3", null, "Message:")
                          ], -1)),
                          (_ctx.selectedItem?.message)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.selectedItem.message), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.showCTA)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          _createElementVNode("button", {
                            class: "cst-button cst-button-primary",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.acceptRequest && _ctx.acceptRequest(...args))),
                            "data-testid": "c-requestdetails-approvebtn"
                          }, "Approve"),
                          _createElementVNode("button", {
                            class: "cst-button cst-button-danger",
                            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.declineRequest && _ctx.declineRequest(...args))),
                            "data-testid": "c-requestdetails-declinebtn"
                          }, "Decline")
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, vShow as _vShow, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../public/newimage.png'


const _hoisted_1 = { class: "cst-inputbox" }
const _hoisted_2 = {
  class: "cst-inputbox--body",
  "data-testid": "newprod-input-box-body"
}
const _hoisted_3 = { class: "cst-inputbox__form" }
const _hoisted_4 = { class: "cst-input-field" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "cst-input-field" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "cst-input-field" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_13 = ["disabled", "placeholder", "max"]
const _hoisted_14 = { class: "cst-input-field" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "v-filter-products__platform-row" }
const _hoisted_18 = {
  key: 0,
  class: "cst-input-field"
}
const _hoisted_19 = ["required", "disabled"]
const _hoisted_20 = { class: "cst-input-field__checkbox" }
const _hoisted_21 = { class: "cst-inputbox__actions" }
const _hoisted_22 = { class: "thumbnail-dropdown" }
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 1,
  "data-testid": "newprod-selected-image-false"
}
const _hoisted_25 = {
  class: "thumbnail-dropdown--content",
  "data-testid": "thumbnail-dropdown"
}
const _hoisted_26 = ["onClick"]
const _hoisted_27 = ["src", "alt"]
const _hoisted_28 = { class: "cst-inputbox__actions--row-wrapper" }
const _hoisted_29 = ["disabled"]
const _hoisted_30 = {
  key: 0,
  class: "cst-inputbox__added-content",
  "data-testid": "newprod-new-product-section"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewProductCard = _resolveComponent("NewProductCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "cst-inputbox--header" }, [
      _createElementVNode("div", { class: "cst-inputbox--header__title" }, "Add a new Product")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
        "data-testid": "newprod-form"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[14] || (_cache[14] = _createElementVNode("label", {
              for: "productowner",
              "data-testid": "newprod-form-label-owner"
            }, [
              _createTextVNode("Owner:"),
              _createElementVNode("span", { style: {"color":"red"} }, "*")
            ], -1)),
            _withDirectives(_createElementVNode("select", {
              class: "cst-select-field",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectOwner) = $event)),
              required: "",
              id: "productowner",
              disabled: _ctx.inputDisabled,
              placeholder: "Select Owner",
              "data-testid": "newprod-select-own"
            }, [
              _cache[13] || (_cache[13] = _createElementVNode("option", {
                disabled: "",
                selected: "",
                value: ""
              }, "Select an owner", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (owner) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: owner.id,
                  value: owner.id,
                  "data-testid": "newprod-owner"
                }, _toDisplayString(owner.username), 9, _hoisted_6))
              }), 128))
            ], 8, _hoisted_5), [
              [_vModelSelect, _ctx.selectOwner]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[16] || (_cache[16] = _createElementVNode("label", { for: "productfamily" }, [
              _createTextVNode("Product Family:"),
              _createElementVNode("span", { style: {"color":"red"} }, "*")
            ], -1)),
            _withDirectives(_createElementVNode("select", {
              class: "cst-select-field",
              required: "",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSelectFamily($event))),
              disabled: _ctx.inputDisabled,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedFamily) = $event)),
              id: "productfamily",
              name: "productfamily",
              "data-testid": "newprod-select-family"
            }, [
              _cache[15] || (_cache[15] = _createElementVNode("option", {
                disabled: "",
                selected: "",
                value: ""
              }, "Select a Product Family", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productFamilies, (family) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: family.id,
                  value: family.name
                }, _toDisplayString(family.name), 9, _hoisted_9))
              }), 128))
            ], 40, _hoisted_8), [
              [_vModelSelect, _ctx.selectedFamily]
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[17] || (_cache[17] = _createElementVNode("label", { for: "name" }, [
              _createTextVNode("Name:"),
              _createElementVNode("span", { style: {"color":"red"} }, "*")
            ], -1)),
            _withDirectives(_createElementVNode("input", {
              autocomplete: "name",
              class: "cst-input",
              type: "text",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.productData.name) = $event)),
              id: "name",
              required: "",
              disabled: _ctx.inputDisabled,
              maxlength: "32",
              "data-testid": "newprod-input-field",
              placeholder: "Enter Product Name"
            }, null, 8, _hoisted_11), [
              [_vModelText, _ctx.productData.name]
            ]),
            _createElementVNode("span", {
              class: _normalizeClass(["cst-input-field__indicator", {
                'cst-input-field__indicator--max': _ctx.productData.name?.length == 32,
              }]),
              "data-testid": "newprod-input-name"
            }, _toDisplayString(_ctx.productData.name?.length ? `${_ctx.productData.name.length}` : `0`) + "/32", 3)
          ]),
          (_ctx.hasBudgetLimit || (_ctx.availableBudget > 0 && _ctx.availableBudget != null && _ctx.availableBudget != undefined))
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[18] || (_cache[18] = _createElementVNode("label", { for: "budgetLimit" }, [
                  _createTextVNode("Budget Limit:"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "number",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.productData.budget_limit) = $event)),
                  id: "budgetLimit",
                  required: "",
                  disabled: _ctx.inputDisabled,
                  "data-testid": "newprod-input-field",
                  placeholder: _ctx.placeholderText,
                  max: _ctx.availableBudget
                }, null, 8, _hoisted_13), [
                  [_vModelText, _ctx.productData.budget_limit]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            _cache[20] || (_cache[20] = _createElementVNode("label", { for: "productregion" }, [
              _createTextVNode("Region:"),
              _createElementVNode("span", { style: {"color":"red"} }, "*")
            ], -1)),
            _withDirectives(_createElementVNode("select", {
              class: "cst-select-field",
              required: "",
              disabled: _ctx.inputDisabled,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedRegion) = $event)),
              id: "productregion",
              onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.handleSelectRegions($event))),
              "data-testid": "newprod-select-regions"
            }, [
              _cache[19] || (_cache[19] = _createElementVNode("option", {
                disabled: "",
                selected: "",
                value: ""
              }, "Select a Region", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.regions, (region) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: region.id,
                  value: region.name
                }, _toDisplayString(region.name), 9, _hoisted_16))
              }), 128))
            ], 40, _hoisted_15), [
              [_vModelSelect, _ctx.selectedRegion]
            ])
          ])
        ]),
        (_ctx.availableBudget)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["cst-input-field__indicator--available", _ctx.availableBudget && Number(_ctx.availableBudget) <= 0 ? 'cst-input-field__indicator--min' : '']),
              "data-testid": "newprod-input-budget-limit"
            }, [
              _cache[21] || (_cache[21] = _createElementVNode("strong", null, "Available amount:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.formatNumber((Number(_ctx.availableBudget) * _ctx.currencyRate).toFixed(2))), 1)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_17, [
          (_ctx.isPlatformSelected)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _cache[22] || (_cache[22] = _createElementVNode("label", { for: "platformCost" }, [
                  _createTextVNode("Enter platform cost value:"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "number",
                  class: "cst-input",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.platformCost) = $event)),
                  id: "platformCost",
                  placeholder: "Enter platform cost value",
                  required: _ctx.isPlatformSelected,
                  disabled: _ctx.inputDisabled
                }, null, 8, _hoisted_19), [
                  [_vModelText, _ctx.platformCost]
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_20, [
            _cache[23] || (_cache[23] = _createElementVNode("label", { for: "selectPlatform" }, "Platform?", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              id: "selectPlatform",
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isPlatformSelected) = $event))
            }, null, 512), [
              [_vModelCheckbox, _ctx.isPlatformSelected]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            (!_ctx.inputDisabled)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "thumbnail-dropdown--btn",
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
                  "data-testid": "thumbnail-dropdown-btn"
                }, [
                  (_ctx.selectedImage)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.selectedImage,
                        alt: "Product",
                        width: "60",
                        height: "40",
                        "data-testid": "newprod-selected-image"
                      }, null, 8, _hoisted_23))
                    : (_openBlock(), _createElementBlock("div", _hoisted_24, "Select an Image"))
                ]))
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", _hoisted_25, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Add new",
                width: "80",
                height: "50",
                style: {"border":"1px solid #cfcfcf"},
                "data-testid": "newprod-upload-image",
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.uploadNewImage()))
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allImages, (image) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: image,
                  "data-testid": "newprod-select-image",
                  onClick: ($event: any) => (_ctx.handleSelectImage(image))
                }, [
                  _createElementVNode("img", {
                    src: image.image_data,
                    alt: image.name,
                    width: "80",
                    height: "50",
                    style: {"object-fit":"contain"}
                  }, null, 8, _hoisted_27)
                ], 8, _hoisted_26))
              }), 128))
            ], 512), [
              [_vShow, _ctx.dropdownOpen]
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("button", {
              class: "submit-button cst-button cst-button-primary",
              type: "submit",
              disabled: _ctx.submitBtnDisabled || (_ctx.availableBudget && Number(_ctx.availableBudget) <= 0),
              "data-testid": "newprod-submit-btn"
            }, "Submit", 8, _hoisted_29),
            (_ctx.productAdded)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "submit-button cst-button cst-button-primary",
                  onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.handleNew()), ["prevent"])),
                  "data-testid": "newprod-new-btn"
                }, "New"))
              : _createCommentVNode("", true)
          ])
        ])
      ], 32)
    ]),
    (_ctx.createdProductFlag)
      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
          _cache[24] || (_cache[24] = _createElementVNode("h4", null, "New Product has been added!", -1)),
          _cache[25] || (_cache[25] = _createElementVNode("h5", null, "You can add Project or Applications directly to it by clicking on the corresponding buttons.", -1)),
          _createVNode(_component_NewProductCard, {
            product: _ctx.newProduct,
            base64image: _ctx.selectedImage
          }, null, 8, ["product", "base64image"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <div class="c-notification__container">
    <div class="c-notification__img-wrapper" @click="handleNotifications" data-testid="notification-icon">
      <img class="c-notification__img" src="../../public/notification.svg" alt="notification icon" />
      <span v-if="totalUnreadNotifications > 0" class="c-notification__img-alert" alt="notification number indicator" data-testid="notification-number">{{ totalUnreadNotifications }}</span>
    </div>
    <div class="c-notification__container">
      <div v-if="showNotifications" class="c-notification__dropdown" data-testid="notification-dropdown">
        <div v-if="notifications == null || (notifications && !notifications.length)" class="c-notification__dropdown--no-notifications">No new notifications...</div>
        <div v-else v-for="notification in notifications.slice(0, 5)" :key="notification.id" style="padding: 5px">
          <div class="c-notification__dropdown-wrapper">
            <div class="c-notification__dropdown-status" v-if="notification.status === null"></div>
            <div class="c-notification__dropdown-content">
              <p
                class="c-notification__dropdown-content-title"
                :class="{
                  'c-notification__dropdown-content-title__read': notification.status != null,
                }"
                @click="openModal(notification.id, notification.title, notification.message, notification.created)"
              >
                {{ notification.title.length > 20 ? notification.title.slice(0, 20) + "..." : notification.title }}
              </p>
              <div class="c-notification__dropdown-content-action-row">
                <p class="c-notification__dropdown-content-action-row-message" @click="openModal(notification.id, notification.title, notification.message, notification.created)" data-testid="open-modal">
                  {{ notification.message.length > 25 ? notification.message.slice(0, 20) + " ..." : notification.message }}
                </p>

                <img
                  class="c-notification__dropdown-content-action-row-status"
                  :class="notification.status !== null ? 'c-notification__dropdown-content-action-row-status' : 'c-notification__dropdown-content-action-row-status-new'"
                  :src="notification.status !== null ? '/mail-open.svg' : '/mail-new.svg'"
                  alt="Notification button read status"
                  data-testid="read-notification"
                  @click="markNotificationAsSeen(notification.id)"
                />

                <img src="../../public/delete.jpg" class="c-notification__dropdown-content-action-row-delete" alt="Delete notification button" data-testid="delete-notification" @click="deleteNotification(notification.id)" />
              </div>
              <p class="c-notification__dropdown-content-time">
                {{ formatDate(notification.created) }}
              </p>
            </div>
          </div>

          <div class="c-notification__dropdown-spacer"></div>
        </div>

        <div class="c-notification__dropdown-viewall" @click="handleViewAll" data-testid="view-all-notifications">View all notifications</div>
      </div>
    </div>
    <NotificationModal />
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onBeforeUnmount, onMounted, watch, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { formatDate } from "@/utils/helpers/formatDate";
  import { useRouter } from "vue-router";
  import NotificationModal from "./modals/C_NotificationModal.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";

  type EventListener = (event: Event) => void;
  export default defineComponent({
    name: "Notification",
    setup() {
      const { FETCH_NOTIFICATIONS, MARK_NOTIFICATION_AS_READ, DEFINE_CURRENT_NOTIFICATION, SHOW_NOTIFICATIONS_DROPDOWN, SHOW_NOTIFICATIONS_MODAL, CHANGE_USER_DROPDOWN } = VUEX_ACTIONS;
      const isOpen = ref(false);
      const store = useStore();
      const router = useRouter();
      const notifications = computed(() => store.getters.getNotifications);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      const currentRoute = computed(() => router.currentRoute.value.fullPath);

      const totalUnreadNotifications = computed(() => {
        let unreadNotifications = 0;
        if (notifications.value.length !== 0 && notifications.value !== undefined && notifications.value !== null) {
          for (let notification of notifications.value) {
            if (notification.status === null) {
              unreadNotifications++;
            }
          }
        }
        return unreadNotifications;
      });

      /* State management for showing the notification dropdown */
      const showNotifications = computed(() => {
        return store.getters.getShowNotifications;
      });

      const markNotificationAsSeen = (notificationId: number) => {
        store.dispatch(MARK_NOTIFICATION_AS_READ, notificationId);
        store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      };

      const deleteNotification = (notificationId: number) => {
        store.dispatch("deleteNotification", notificationId);
        store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      };

      const handleNotifications = () => {
        store.dispatch(SHOW_NOTIFICATIONS_DROPDOWN, !showNotifications.value);
        store.dispatch(CHANGE_USER_DROPDOWN, false);
        store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      };

      const handleViewAll = () => {
        store.dispatch(SHOW_NOTIFICATIONS_DROPDOWN, false);
        router.push("/notifications");
      };

      const openModal = (id: number, title: string, message: string, date: Date) => {
        store.dispatch(SHOW_NOTIFICATIONS_MODAL, true);
        const payload = { id, title, message, date };
        store.dispatch(DEFINE_CURRENT_NOTIFICATION, payload);
        markNotificationAsSeen(id);
        store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
      };

      let notificationsFetchInterval: number;

      const fetchAllNotifications = () => {
        store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);
        notificationsFetchInterval = setInterval(() => store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value), 180000);
      };

      onMounted(() => {
        fetchAllNotifications();
        document.addEventListener("click", handleClickOutside as EventListener);
      });

      onUnmounted(() => {
        document.removeEventListener("click", handleClickOutside as EventListener);
      });

      const handleClickOutside = (event: PointerEvent) => {
        const dropdown = document.querySelector(".c-notification__container");
        const isClickedOutsideDropdown = dropdown && !(event.target as Element).matches(".c-notification__img") && !(event.target as Element).closest(".c-notification__dropdown");

        if (isClickedOutsideDropdown) {
          store.dispatch(SHOW_NOTIFICATIONS_DROPDOWN, false);
        }
      };

      onBeforeUnmount(() => {
        if (notificationsFetchInterval) {
          clearInterval(notificationsFetchInterval);
        }
      });

      watch([currentRoute], () => {
        store.dispatch(SHOW_NOTIFICATIONS_DROPDOWN, false);
      });

      return {
        handleNotifications,
        handleViewAll,
        isOpen,
        formatDate,
        showNotifications,
        openModal,
        totalUnreadNotifications,
        notifications,
        markNotificationAsSeen,
        deleteNotification,
      };
    },
    components: { NotificationModal },
  });
</script>

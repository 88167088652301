<template>
  <div class="v-importcsv__wrapper">
    <h1>Import CSV file</h1>
    <input class="v-importcsv__input" type="file" ref="fileInput" @change="onFileChange" />
    <div>
      <button type="button" class="cst-button cst-button-primary v-importcsv__uploadbtn" @click="uploadFile" :disabled="selectedFile === null">Upload CSV</button>
    </div>
    <div v-if="isUploading">
      <div class="cst-spinner"></div>
      <div>
        <p class="v-importcsv__text-center">Upload in progress, please wait...</p>
      </div>
    </div>
    <div v-else class="v-importcsv__messages">
      <p v-if="uploadComplete" class="v-importcsv__messages-complete v-importcsv__text-center">Upload complete!</p>
      <div class="v-importcsv__upload-log" v-if="uploadComplete">
        <p class="v-importcsv__messages-green">
          Succesful Imports:
          <span class="v-importcsv__bold">{{ successImports }}</span>
        </p>
        <router-link to="/invoices">
          <button type="button" class="cst-button v-importcsv__upload-log-btn">Go to Invoices</button>
        </router-link>
        <p class="v-importcsv__messages-pink">
          Failed Imports:
          <span class="v-importcsv__bold">{{ failedInputs.length }}</span>
        </p>
        <div class="v-importcsv__upload-log-btn-wrapper">
          <button type="button" v-if="!hideDownloadBtns" class="cst-button v-importcsv__upload-log-btn" @click="downloadPDF">Download PDF</button>
        </div>
      </div>
    </div>
  </div>
  <div id="csv-import-table-container" class="v-importcsv__table-container" v-if="failedInputs && failedInputs.length > 0">
    <h3 v-if="failedInputs.length > 0">CSV Errors</h3>
    <table id="export-table" style="overflow: hidden" class="v-importcsv__table" aria-describedby="Failed imports table">
      <thead>
        <tr class="v-importcsv__table-row">
          <th v-if="failedInputs.length > 0" class="v-importcsv__table-error-warning">Error</th>
          <th v-for="key in copiedTableHeaders" :key="key">{{ key }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in failedInputs" :key="index" class="v-importcsv__table-row">
          <td v-if="failedInputs.length > 0" class="v-importcsv__table-error-warning">
            <p v-for="error in item.errors" :key="error">{{ error }}</p>
          </td>
          <td v-for="key in copiedTableHeaders" :key="key">
            {{ (item as any)[key] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else class="v-importcsv__no-failed-inputs v-importcsv__text-center">
    <p v-if="successImports">No failed inputs to display.</p>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from "vue";
  import axios from "axios";
  import { CST_BACKEND_URL } from "@/store/config";
  // eslint-disable-next-line
  const domToPdf = require("dom-to-pdf");
  import { IInvoiceCSV } from "@/utils/interfaces/IInvoice";
  import store from "@/store";
  import { VUEX_ACTIONS } from "@/utils/constants";

  export default defineComponent({
    name: "ImportInvoiceCsv",
    beforeRouteEnter(to: any, from: any, next: any) {
      const appConfiguration = computed(() => store.getters.getAppConfiguration);

      const invoicesEnabled = computed(() => {
        if (Object.keys(appConfiguration.value).length > 0) {
          return appConfiguration.value.invoice_importer_enabled;
        } else {
          return true;
        }
      });

      if (invoicesEnabled.value) {
        next();
      } else {
        next("/");
      }
    },

    setup() {
      const { FETCH_NOTIFICATIONS } = VUEX_ACTIONS;
      const selectedFile = ref<File | null>(null);
      const failedInputs = ref<IInvoiceCSV[]>([]);
      const successImports = ref<number>(0);
      const uploading = ref<boolean>(false);
      const progress = ref<number>(0);
      const isUploading = ref(false);
      const uploadComplete = ref(false);
      const showError = ref<string>("");
      const tableHeaders = ref<string[]>([]);
      const copiedTableHeaders = ref<string[]>([]);
      const hideDownloadBtns = ref(true);
      const token = computed(() => store.getters.getToken);
      const loggedUser = computed(() => store.getters.getCurrentUser);
      store.dispatch(FETCH_NOTIFICATIONS, loggedUser.value);

      watch(failedInputs, (newFailedInputs) => {
        if (newFailedInputs.length > 0) {
          copiedTableHeaders.value = Object.keys(newFailedInputs[0]);
          copiedTableHeaders.value.pop();
        }
      });

      const onFileChange = (event: Event) => {
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.files && inputElement.files.length > 0) {
          selectedFile.value = inputElement.files[0];
        }
      };

      // WARNING: This function downloads the table exactly as shown in the DOM.
      // TODO: Implement a solution to create PDF without relying to visible DOM
      const downloadPDF = () => {
        const element = document.getElementById("csv-import-table-container");
        const options = {
          filename: "error-logs.pdf",
        };
        domToPdf(element, options, function () {
          console.log("Error logs downloaded");
        });
      };

      const uploadFile = () => {
        if (selectedFile.value) {
          isUploading.value = true;
          const formData = new FormData();
          formData.append("file", selectedFile.value);

          uploading.value = true;
          progress.value = 0;

          axios
            .post(`${CST_BACKEND_URL}/invoices/csv`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token.value}`,
              },
            })
            .then((response) => {
              failedInputs.value = response.data.errorRows;
              console.log(response.data.results.length);
              successImports.value = response.data.results.length;

              if (failedInputs.value.length > 0) {
                tableHeaders.value = Object.keys(failedInputs.value[0]);
                showError.value = failedInputs.value[0].errors;
              } else {
                showError.value = "";
              }
            })
            .catch((error) => {
              console.error("Error uploading file:", error);
            })
            .finally(() => {
              isUploading.value = false;
              uploadComplete.value = true;
              selectedFile.value = null;
              if (failedInputs.value.length > 0) {
                hideDownloadBtns.value = false;
              }
            });
        } else {
          console.log("No file selected");
        }
      };

      return {
        selectedFile,
        failedInputs,
        successImports,
        copiedTableHeaders,
        onFileChange,
        uploadFile,
        downloadPDF,
        hideDownloadBtns,
        isUploading,
        uploadComplete,
        uploading,
        progress,
        showError,
        tableHeaders,
      };
    },
  });
</script>

import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../../public/remove-white.svg'


const _hoisted_1 = { class: "c-future-budget-modal--background" }
const _hoisted_2 = { class: "c-future-budget-modal" }
const _hoisted_3 = { class: "c-future-budget-modal__header" }
const _hoisted_4 = { class: "c-future-budget-modal__container" }
const _hoisted_5 = { class: "c-future-budget-modal__container__messages c-future-budget-modal--budget-approval-data" }
const _hoisted_6 = { class: "c-future-budget-modal__container-budget" }
const _hoisted_7 = {
  class: "c-future-budget-modal__container-budget--value",
  "data-testid": "budget-approval-data-value"
}
const _hoisted_8 = { class: "c-future-budget-modal__container-budget" }
const _hoisted_9 = { class: "c-future-budget-modal__container-budget--value" }
const _hoisted_10 = {
  key: 0,
  class: "c-future-budget-modal__approved-budget--wrapper"
}
const _hoisted_11 = { class: "cst-input-field" }
const _hoisted_12 = {
  key: 1,
  class: "c-future-budget-modal__decline-reason"
}
const _hoisted_13 = { class: "cst-input-field" }
const _hoisted_14 = { class: "c-future-budget-modal__footer c-future-budget-modal__footer--button-wrapper" }

import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { onBeforeUnmount, onMounted, ref, computed } from "vue";
  import { useStore } from "vuex";
  import { IUser } from "@/utils/interfaces/IUser";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'C_FutureBudgetDecisionModal',
  props: {
    entity: {},
    isApproval: { type: Boolean }
  },
  emits: ["close-decision-modal"],
  setup(__props: any, { emit: __emit }) {

  const { APPROVE_FUTURE_BUDGET_PLAN, DECLINE_FUTURE_BUDGET_PLAN } = VUEX_ACTIONS;
  const props = __props;
  const emit = __emit;
  const store = useStore();
  const message = ref("");
  const users = computed(() => store.getters.getUsers);
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currentUser = computed(() => store.getters.getCurrentUser);
  const approvedBudget = ref<number>();
  const currentUserId = computed(() => {
    const userId = users.value.find((user: IUser) => user.username === currentUser.value);
    return userId.id;
  });
  const closeModal = () => {
    store.commit("setSpecificFutureBudget", null);
    emit("close-decision-modal");
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  const handleSubmit = () => {
    if (props.isApproval) {
      const payload = {
        id: props.entity.id,
        approved_budget: approvedBudget.value,
        status: "approved",
      };
      store.dispatch(APPROVE_FUTURE_BUDGET_PLAN, payload);
      closeModal();
    } else {
      const payload = {
        id: props.entity.id,
        sender_id: currentUserId.value,
        description: message.value,
        status: "declined",
      };
      store.dispatch(DECLINE_FUTURE_BUDGET_PLAN, payload);
      closeModal();
    }
  };

  onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleKeydown);
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(props.isApproval ? "Approve future budget?" : "Decline future budget"), 1),
        _createElementVNode("img", {
          src: _imports_0,
          alt: "close modal button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal())),
          "data-testid": "future-budget-closemodal-button"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "c-future-budget-modal__container-budget--label" }, "Proposed Budget:", -1)),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.entity.proposed_budget ? _unref(formatNumber)((Number(_ctx.entity.proposed_budget) * currencyRate.value).toFixed(2)) + " " + currency.value : "- EUR"), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "c-future-budget-modal__container-budget--label" }, "Demanded Budget:", -1)),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.entity.demanded_budget ? _unref(formatNumber)((Number(_ctx.entity.demanded_budget) * currencyRate.value).toFixed(2)) + " " + currency.value : "- EUR"), 1)
          ])
        ]),
        (_ctx.isApproval)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { for: "approved-budget" }, [
                  _createTextVNode(" Enter approved budget"),
                  _createElementVNode("span", { style: {"color":"red"} }, "*")
                ], -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "cst-input",
                  type: "number",
                  id: "approved-budget",
                  min: "1",
                  required: "",
                  placeholder: '0',
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((approvedBudget).value = $event))
                }, null, 512), [
                  [_vModelText, approvedBudget.value]
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _cache[7] || (_cache[7] = _createElementVNode("label", { for: "comment" }, "Decline reason", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  class: "cst-input c-future-budget-modal__messagebox",
                  type: "textarea",
                  id: "comment",
                  maxlength: "240",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((message).value = $event))
                }, null, 512), [
                  [_vModelText, message.value]
                ]),
                _createElementVNode("span", {
                  class: _normalizeClass(["cst-input-field__indicator", {
                'cst-input-field__indicator--max': message.value?.length == 240,
              }])
                }, _toDisplayString(message.value?.length ? `${message.value.length}` : `0`) + "/240", 3)
              ])
            ]))
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("button", {
          type: "button",
          class: "cst-button-primary c-productcard__button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (handleSubmit()))
        }, _toDisplayString(props.isApproval ? "Approve" : "Decline"), 1),
        _createElementVNode("button", {
          type: "button",
          class: "cst-button-primary c-productcard__button",
          onClick: closeModal
        }, "Cancel")
      ])
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "v-budgetoverview__main-container",
  "data-testid": "budgetoverview-wrapper"
}
const _hoisted_2 = { class: "v-filter-products__toggle-view" }

import C_BudgetRequestReview from "@/components/C_BudgetRequestReview.vue";
  import C_FutureBudgetTableOverview from "@/components/C_FutureBudgetTableOverview.vue";
  import { VUEX_ACTIONS } from "@/utils/constants";
  import { ref } from "vue";
  import { useStore } from "vuex";
  
export default /*@__PURE__*/_defineComponent({
  __name: 'V_BudgetOverview',
  setup(__props) {

  const { FETCH_USERS } = VUEX_ACTIONS;
  const store = useStore();
  const isGridView = ref(true);
  store.dispatch(FETCH_USERS);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "v-polist__title-all" }, "Budget Overview", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: './grid.svg',
        alt: "grid view",
        style: _normalizeStyle(isGridView.value ? { border: '1px solid #525252' } : ''),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isGridView.value = !isGridView.value)),
        "data-testid": "budgetoverview-img-grid"
      }, null, 4),
      _createElementVNode("img", {
        src: './list.svg',
        alt: "list view",
        style: _normalizeStyle(!isGridView.value ? { border: '1px solid #525252' } : ''),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (isGridView.value = !isGridView.value)),
        "data-testid": "budgetoverview-img-list"
      }, null, 4)
    ]),
    (isGridView.value)
      ? (_openBlock(), _createBlock(C_BudgetRequestReview, {
          key: 0,
          isoverview: true,
          "data-testid": "budgetoverview-child-budgetrequestreview"
        }))
      : _createCommentVNode("", true),
    (!isGridView.value)
      ? (_openBlock(), _createBlock(C_FutureBudgetTableOverview, {
          key: 1,
          "data-testid": "budgetoverview-child-futurebudgetableoverview"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})
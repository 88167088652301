import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../../public/remove.svg'


const _hoisted_1 = { class: "c-listitemcard__content" }
const _hoisted_2 = { "data-testid": "listitemcard-name" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isDetails)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "c-listitemcard__deletebtn",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeItem && _ctx.removeItem(...args))),
          "data-testid": "listitemcard-deletebtn"
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Remove list item button"
          }, null, -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.data.name), 1),
    _createElementVNode("p", null, [
      _cache[2] || (_cache[2] = _createElementVNode("span", null, "Position: ", -1)),
      _createTextVNode(_toDisplayString(_ctx.data.position), 1)
    ]),
    _createElementVNode("p", null, [
      _cache[3] || (_cache[3] = _createElementVNode("span", null, "Type: ", -1)),
      _createTextVNode(_toDisplayString(_ctx.isDetails ? _ctx.data.costtype.split(" ")[0] : _ctx.data.type), 1)
    ]),
    _createElementVNode("p", null, [
      _cache[4] || (_cache[4] = _createElementVNode("span", null, "Cost Type: ", -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.isDetails ? _ctx.data.costtype.split(" ")[1] : _ctx.data.costtype ? _ctx.data.costtype : "-"), 1)
    ]),
    (_ctx.data?.resourcename?.length > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("span", null, "Resource Name: ", -1)),
          _createTextVNode(_toDisplayString(_ctx.data.resourcename), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.quantity > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("span", null, "Person Days: ", -1)),
          _createTextVNode(_toDisplayString(_ctx.data.quantity), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("p", null, [
      _cache[7] || (_cache[7] = _createElementVNode("span", null, "Amount: ", -1)),
      _createTextVNode(_toDisplayString(_ctx.formatNumber((Number(_ctx.data.amount) * _ctx.currencyRate).toFixed(2)) + " " + (_ctx.selectedCurrency || _ctx.data.currency)), 1)
    ])
  ]))
}
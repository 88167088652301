<template>
  <div class="c-budgetcard__wrapper" data-testid="budgetcard-wrapper">
    <p class="c-budgetcard__product-name" data-testid="budgetcard-name">
      {{ entity?.entity_name ? entity.entity_name : "Future Budget" }}
    </p>
    <p
      class="c-budgetcard__type"
      :class="{
        'c-budgetcard__type--review': entity?.status === 'proposed',
        'c-budgetcard__type--declined': entity?.status === 'declined',
        'c-budgetcard__type--approved': entity?.status === 'approved',
      }"
      data-testid="budgetcard-status"
    >
      {{ entity?.status ? entity.status : "Proposed" }}
    </p>

    <div class="c-budgetcard__owner-wrapper">
      <p class="c-budgetcard__owner-label">Owner:</p>
      <p data-testid="budgetcard-owner">
        {{ entity?.owner ? entity.owner : "-" }}
      </p>
    </div>
    <div class="c-budgetcard__summary">
      <div class="c-budgetcard__row">
        <div class="section__line-with-text">
          <p class="section__title">Demanded</p>
        </div>
      </div>
      <div class="c-budgetcard__row">
        <p class="c-budgetcard__row--placeholder">Demand ID</p>
        <p class="c-budgetcard__row--value" data-testid="budgetcard-demand-id">
          {{ entity?.demand_id ? entity.demand_id : "-" }}
        </p>
      </div>
      <div class="c-budgetcard__row">
        <p class="c-budgetcard__row--placeholder">Demand Title</p>
        <p class="c-budgetcard__row--value" data-testid="budgetcard-demand-title">
          {{ entity?.demand_title ? entity.demand_title : "-" }}
        </p>
      </div>
    </div>
    <div class="c-budgetcard__summary">
      <div class="c-budgetcard__row">
        <p class="c-budgetcard__row--placeholder">Demanded Budget</p>
        <p class="c-budgetcard__row--value" data-testid="budgetcard-demand-value">
          {{ entity?.demanded_budget ? formatNumber((Number(entity.demanded_budget) * currencyRate).toFixed(2)) + " " + currency : "- EUR" }}
        </p>
      </div>
      <div class="c-budgetcard__row">
        <div class="section__line-with-text">
          <p class="section__title">Proposed</p>
        </div>
      </div>
      <div class="c-budgetcard__row">
        <p class="c-budgetcard__row--placeholder">Proposed Budget</p>
        <p class="c-budgetcard__row--value" data-testid="budgetcard-proposed-value">
          {{ entity?.proposed_budget ? formatNumber((Number(entity.proposed_budget) * currencyRate).toFixed(2)) + " " + currency : "- EUR" }}
        </p>
      </div>
      <div class="c-budgetcard__row">
        <p class="c-budgetcard__row--placeholder">Approved Budget</p>
        <p class="c-budgetcard__row--value" data-testid="budgetcard-approved-value">
          {{ entity?.approved_budget ? formatNumber((Number(entity.approved_budget) * currencyRate).toFixed(2)) + " " + currency : "- EUR" }}
        </p>
      </div>

      <div class="c-budgetcard__button--wrapper">
        <button
          type="button"
          class="cst-button-primary c-productcard__button"
          @click="$emit('future-budget-approve', entity)"
          v-if="!props.isoverview && router.currentRoute.value.path.toLowerCase() == '/budget-proposal' && entity?.proposed_by == currentUser"
          data-testid="budgetcard-btn-approve"
        >
          Approve
        </button>
        <button
          type="button"
          class="cst-button-primary c-productcard__button"
          @click="$emit('future-budget-decline', entity)"
          v-if="!props.isoverview && router.currentRoute.value.path.toLowerCase() == '/budget-proposal' && entity?.proposed_by == currentUser"
          data-testid="budgetcard-btn-decline"
        >
          Decline
        </button>
        <button type="button" class="cst-button-primary c-productcard__button c-budgetcard__button--details" @click="$emit('future-budget-details', entity)" data-testid="budgetcard-btn-details">Details</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { IBudgetPlan } from "@/utils/interfaces/IBudgetPlan";
  import { computed, defineProps } from "vue";
  import { formatNumber } from "@/utils/helpers/formatNumber";
  import { useRouter } from "vue-router";
  import { useStore } from "vuex";

  const store = useStore();
  const router = useRouter();
  const currencyRate = computed(() => store.getters.getCurrencyRate);
  const currency = computed(() => store.getters.getSelectedCurrency);
  const currentUser = computed(() => store.getters.getCurrentUser);

  const props = defineProps<{
    entity: IBudgetPlan;
    isoverview: boolean;
  }>();
</script>

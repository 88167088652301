import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "c-productchart__container",
  "data-testid": "product-chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Bar, {
        id: "pf-currentyear-chart",
        options: _ctx.chartOptions,
        data: _ctx.chartData
      }, null, 8, ["options", "data"]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "c-productchart__labels" }, [
        _createElementVNode("div", null, "Budget"),
        _createElementVNode("div", null, "Forecast"),
        _createElementVNode("div", null, "Actuals"),
        _createElementVNode("div", null, "PO's")
      ], -1))
    ])
  ]))
}
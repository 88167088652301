import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "c-pflastnextyear-chart__container"
}
const _hoisted_3 = { class: "c-pflastnextyear-chart__note" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bar = _resolveComponent("Bar")!

  return (_ctx.dataLoaded && _ctx.products.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.chartData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Bar, {
                id: "pf-currentyear-chart",
                options: _ctx.chartOptions,
                data: _ctx.chartData
              }, null, 8, ["options", "data"]),
              _createElementVNode("div", _hoisted_3, "*Amounts are shown in " + _toDisplayString(_ctx.currency), 1)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}